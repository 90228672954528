
import Vue from "vue";
import { Location } from "vue-router";
import { PropValidator } from "vue/types/options";

import { FilesystemNodeShortcut, isShortcutNode } from "@/common/fs";
import { ROUTES } from "@/router";

import AxDate from "@/components/AxDate.vue";
import AxProjectState from "@/components/AxProjectState.vue";

const projectProp: PropValidator<FilesystemNodeShortcut> = {
  type: Object,
  required: false,
  validator(item) {
    return isShortcutNode(item);
  },
};
const projectModifiedProp = {
  type: [Date, String],
  required: false,
};
const projectStateProp = {
  type: String,
  required: false,
};
const projectIdProp = {
  type: String,
  required: false,
};

export default Vue.extend({
  components: {
    AxProjectState,
    AxDate,
  },

  props: {
    project: projectProp,
    projectId: projectIdProp,
    projectModified: projectModifiedProp,
    projectState: projectStateProp,
  },

  computed: {
    modified(): Date | string | undefined {
      if (this.project) {
        return this.project.updated;
      }

      return this.projectModified;
    },

    historyTabRoute(): Location {
      return {
        name: ROUTES["project.history"].name,
        params: { shortcut: this.projectId || this.project.id },
      };
    },

    state(): string {
      if (this.projectModified) {
        return "Ok";
      }
      return this.projectState;
    },
  },
});
