
/* eslint-disable max-len */
import Vue from "vue";

interface Release {
  title: string;
  isSubtitle?: true;
  isSupertitle?: true,
  date?: string;
  points?: string[];
}

const releases: Release[] = [
  {
    title: "Axure Cloud for Business On Premises 2.0.0.422",
    date: "December 1, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from November 29, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "November 29, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Implemented searchable list in dialogs when moving prototypes or when adding/muting notifications",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Fixed daily notification emails not sending with own publish/comment activity",
      "Fixed member prototype link opening without current configuration",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Cleaned up emails when new instance/user is created and when migrating workspaces",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.421",
    date: "November 14, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from November 9, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Removed legacy notifications from On Premises setups",
      "Updated RP10 generator to 3887",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "November 9, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Updated Jira/Confluence integration to work with prototypes containing pretty URL settings",
      "Updated UX of Jira/Confluence integration",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Fixed Microsoft Teams notifications not appearing if comments contained special characters",
      "Removed legacy notifications from Axure Cloud",
      "Further improvements for notification infrastructure",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.420",
    date: "October 31, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from October 26, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Updated RP10 generator to 3886",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "October 26, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Refreshed UI",
      "Search bar now persists across all pages",
      "Axure logo now redirects to Recents",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Improved notification timezone infrastructure",
      "Workspace members can now access member links in Axure Cloud via Jira/Confluence when an embedded prototype is made private",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.419",
    date: "October 21, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from October 12, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "October 12, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Disabled project URLs now redirect to member links",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Enabling team project link from Axure RP will update share dialog upon opening",
      "Infrastructure improvements",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.418",
    date: "September 26, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from September 22, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "September 22, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Comment filter popup will stay open after selecting filter options",
      "Updates to Create New Project screens",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Fixed issue with clicking plugin button in the Chrome browser",
      "Fixed issue with Pretty URLs settings not working",
      "Security improvements",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.417",
    date: "September 9, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from September 6, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "September 6, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "The Project History page will show timestamps in full date and time format",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Fixed comments being marked as unread after moving projects between workspaces",
      "Fixed issue with downloading assets from Inspect",
      "Increased font size in comments filter popup",
      "Removed the Post button while editing a comment",
      "Better handling of loading the Axure Handwriting Font when the page is set to Low Fidelity mode",
      "Improved performance when working with Axure Cloud",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.416",
    date: "August 25, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from August 23, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "August 23, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Updated color scheme of Axure Cloud project icons to match Axure RP's file icons",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Improved performance when working in Axure Cloud",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.416",
    date: "August 25, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from August 23, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "August 23, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Updated color scheme of Axure Cloud project icons to match Axure RP's file icons",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Improved performance when working in Axure Cloud",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.415",
    date: "August 16, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from August 9, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Updated RP10 generator to 3882",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "August 9, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Renaming a project no longer changes its date modified",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Fixed issue where comment dialog wouldn’t appear when moving it to the edge of the screen",
      "Security improvements",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.414",
    date: "July 26, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Incorporates changes from July 19, 2022 update to Axure Cloud",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Fixed issue where generating a project with 0 pages selected would show an error in the public link",
      "Updated RP10 generator to 3879",
      "Updated RP9 generator to 3781",
    ],
  },
  {
    title: "Axure Cloud Update",
    date: "July 19, 2022",
  },
  {
    title: "Feature Updates",
    isSubtitle: true,
    points: [
      "Projects now automatically refresh when generation is finished",
      "Updated project generation page with new UI",
      "Updated Access Code page with new UI",
    ],
  },
  {
    title: "Bug Fixes & Performance Enhancements",
    isSubtitle: true,
    points: [
      "Fixed issue where phishing banner links were not clickable on iOS devices",
      "Fixed issue with comment dialog appearing behind the comment pane",
      "Fixed issue with comment placement after resizing browser window",
      "Fixed issue with artboards appearing too large when exported from Adobe XD or Sketch",
      "Fixed issue with Inspect reporting incorrect font weights when widgets had overwritten styles for fonts",
      "Project generation should no longer fail when an RP 10 team project only contains empty folders",
      "Improved Recents workspace to only show projects within 60 days",
      "Security Improvements",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.413",
    date: "June 23, 2022",
    points: [
      "Incorporates changes from June 21, 2022 update to Axure Cloud",
      "Updated RP9 generator to 3780",
      "Updated RP10 generator to 3876",
    ],
  },
  {
    title: "Axure Cloud Update: June 21, 2022",
    points: [
      "New ability to filter comments by unread, resolved, or comment color pin for RP 9 and RP 10 prototypes",
      "New ability to scroll while commenting on artboard projects",
      "Artboard projects now match the UI of the RP prototype player",
      "Fixed issue where scrolling within the comment would scroll the entire prototype",
      "Fixed issue where shadow properties were not appearing in Inspect for dynamic panels",
      'Fixed issue where clicking the "@" icon or emoji icon in the comment reply dialog would focus back on the text area',
      "Fixed bug with taking snapshots in comments",
      "Optimization improvements",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.412",
    date: "May 31, 2022",
    points: [
      "Incorporates changes from May 25, 2022 update to Axure Cloud",
      "Fixed issue with generating projects containing a snapshot widget referencing a page with an SVG",
      "Updated RP10 generator to 3873",
    ],
  },
  {
    title: "Axure Cloud Update: May 25, 2022",
    points: [
      "New commenting improvements for RP projects",
      "Updated text on project overview page to reflect the number of widgets in widget libraries",
      "Fixed issue with loading notification badges on public Cloud links",
      "Fixed issue with loading Cloud accounts containing a [space] at the end of the email",
      "Security improvements",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Added endpoint for improved verification of Enterprise licenses",
    ],
  },

  {
    title: "Axure Cloud for Business On Premises 2.0.0.411",
    date: "April 27, 2022",
    points: [
      "Incorporates changes from April 20, 2022 update to Axure Cloud",
      "Updated RP10 generator to 3870",
    ],
  },
  {
    title: "Axure Cloud Update: April 20, 2022",
    points: [
      "Updated and refreshed UI style applied to the prototype player matching the latest RP 10 build",
      'New "Share" functionality in the prototype player allowing you to easily share prototypes links with others while still viewing the prototype',
      "You can now mark comments as unread, making it easier to manage your comment workflows",
      "Added ability to copy links to individual comments, great for directing team members to a specific location on the prototype",
      "Publish dialogs now contain more concise Cloud URLs",
      "New hotkey - pressing [Esc] while adding a comment will allow you to quickly exit comment mode",
      "Added ability to downgrade RP 10 team projects back to RP 8 team projects in support of some new RP workflows",
      "Improved workflow when requesting to join a workspace",
      "Fixed issue with loading artboards in new browser tab/window",
      "Fixed issue with loading History tab for heavy projects",
      'Fixed issue where the "Invite" button in project overview was visible for reviewers in workspaces',
      "Fixed issue with reopening pages panel for RP 8 projects",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      'Fixed issue where "Technical Admins" could downgrade "Super Admins" to a lower access level',
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.409",
    date: "March 8, 2022",
    points: [
      "Incorporates changes from March 3, 2022 update to Axure Cloud",
      "Updated RP10 generator to 3860",
    ],
  },
  {
    title: "Axure Cloud Update: March 3, 2022",
    points: [
      "Implemented additional caching to improve prototype performance",
      "Fixed issue with general lag around deleting plugins, guest subscriptions, and workspaces",
      "Fixed comment counts not appearing correctly on workspaces with folders containing projects with 0 comments",
      "Fixed issue with alignment when deleting access codes on RP8- projects",
      "Security improvements",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Fixed issue where instance can get into a state without a Super Admin",
      "Fixed issue with alignment on saving SAML settings",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.408",
    date: "February 7, 2022",
    points: [
      "Incorporates changes from sunsetting legacy Axure Share on November 30, 2021",
      "Incorporates changes from January 11, 2022 updates to Axure Cloud",
      "Fixed issue with widget notes in dynamic panels appearing out of alignment",
      "Updated RP9 generator to 3757 and RP10 generator to 3857",
    ],
  },
  {
    title: "Axure Cloud Update: January 11, 2022",
    points: [
      "Improved upon the prototype sharing experience for public and member links. These improvements make it easier to customize the initial viewing experience for your stakeholders when clicking on the share link",
      "Fixed issue with pretty URLs redirecting to the default page when an Access Code is applied",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.407",
    date: "November 17, 2021",
    points: [
      "Incorporates changes from November 10, 2021 update to Axure Cloud",
      "Updated RP10 generator to 3851",
    ],
  },
  {
    title: "Axure Cloud Update: November 10, 2021",
    points: [
      "Email notifications will now reflect your current time zone; this has been implemented for both regular as well as guest accounts",
      "You can now manually adjust your Cloud account's time zone via the Notification Center page",
      "Prototype share link URLs can now be copied directly from the project overview page",
      "Updates to the device list for artboard projects",
      "Fixed an issue with blank pages for locally downloaded projects in the mobile app",
      "Fixed an issue downloading projects locally on the mobile app when only some pages were set to generate",
      "Fixed an issue with Cloudfront URLs replacing image extensions in certain file setups",
      "Fixed Polish characters rending incorrectly when using Open Sans font",
      "Fixed font styles rending incorrectly between different adaptive views",
      "Fixed generation error when no pages were set to generate",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      'Added "Limit Access" option when creating a new workspace',
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.406",
    date: "September 29, 2021",
    points: [
      "Incorporates changes from September 23, 2021 update to Axure Cloud",
      "Fixed issue with Chinese characters rendering incorrectly on publish",
      "Fixed issue with workspace not loading due to high comment count",
      "Updated RP10 generator to 3844",
    ],
  },
  {
    title: "Axure Cloud Update: September 23, 2021",
    points: [
      'Pretty URLs and Redirect functionality is now supported for RP projects in Axure Cloud under the "Advanced" tab',
      "Added ability to view prototype Access Codes",
      "Inspect now surfaces padding properties and image file types",
      "Fixed issue with maintaining image file types from projects created in Axure RP",
      "Fixed issue with border properties in Inspect",
      "Fixed issue with component changes missing in Team Project history for RP 10 projects",
      "Fixed issue with border highlight when renaming a workspace in Firefox and Safari ",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      'Prototype Passwords are now referred to as "Access Codes"',
      "Added ability to easily change the Super Admin of your instance",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.405",
    date: "August 24, 2021",
    points: [
      "Incorporated changes from August 18, 2021 update to Axure Cloud",
      "Updated RP10 generator to 3838",
    ],
  },
  {
    title: "Axure Cloud Update: August 18, 2021",
    points: [
      "You can now download multiples images at once while inspecting",
      "Fixed pinning comments for large prototypes",
      "Fixed pinning comments when zooming in/out with custom device settings",
      "Fixed invitation icon placement in workspace list",
      "Fixed plugin placement with multiple pages or long page names",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Improved Cloud license setup and management",
      "Fixed total comment count to include unread @mentions",
      "Enhanced Axure Cloud security",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.404",
    date: "July 14, 2021",
    points: [
      "Incorporated changes from July 12, 2021 update to Axure Cloud",
      "Removed customsettings.readme file for manual installs of OnPrem",
      "Updated RP10 Generator to 3830",
    ],
  },
  {
    title: "Axure Cloud Update: July 12, 2021",
    points: [
      "Improved formatting and organization of Team Project commit notes",
      "Updated @mention styling",
      "Improved organization and readability of comment metadata within the comment panel",
      "New crisp RP projects icons",
      "Improved product security",
      "Fixed issue opening comments on pages with a horizontal scroll",
      "Fixed issue scrolling a prototype while using Inspect with the Confluence Cloud integration",
      "Fixed issue triggering interactions in masters for artboard projects",
      "Fixed icons not appearing on Private/Recents workspaces",
      'Fixed "Copy Code" button not appearing in Firefox',
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Increased grace period length to 14 days for an expired Cloud license",
      "Expiration emails are now sent to all admins 30 days prior to an annual Cloud license expiring",
      "Fixed issue with lines disappearing on hover for project/user lists",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.403",
    date: "June 01, 2021",
    points: [
      "Incorporates changes from May 24, 2021 update to Axure Cloud",
      "Updated RP10 Generator to 3828",
    ],
  },
  {
    title: "Axure Cloud Update: May 24, 2021",
    points: [
      "UI refresh!",
      "Backend framework update",
      "Fixed Artboard projects not zooming correctly on Firefox",
      "Fixed background image availability in Inspect",
      "Fixed trimmed leading quotes for fonts in Inspect",
      "Updated user prototype limit text",
      "Fixed intermittent errors with dormant tabs",
      "Fixed menu appearing in What's New dialog",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.402",
    date: "April 29, 2021",
    points: [
      "Incorporates changes from April 22, 2021 update to Axure Cloud",
      "Added support for RP10",
      "Fixed issue verifying Enterprise users",
      "Updated RP10 Generator to 3824",
      "Updated RP9 Generator to 3727",
    ],
  },
  {
    title: "Axure Cloud Update: April 22, 2021",
    points: [
      "Inspect now available in public link (RP10 and Artboard projects)",
      "Pan & Zoom added to prototypes (RP10)",
      "Synching of prototype scroll and zoom settings for Preview/Inspect transitions (RP10)",
      "Updated zoom options for Artboard projects",
      "Updated RP10 icons",
      "Fixed comment bubble locations when not in scroll frame",
      "Fixed preview page sticking after new project generation",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.331",
    date: "April 12, 2021",
    points: [
      "Added support for SMTP anonymous authentication",
      "Fixed upgrade to secure SMTP connection",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.330",
    date: "April 5, 2021",
    points: [
      "Incorporates changes from April 2, 2021 update to Axure Cloud",
    ],
  },
  {
    title: "Axure Cloud Update: April 2, 2021",
    points: [
      "General updates for Axure RP10 support",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Fixed some Reviewer level users having Team Projects access",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.328",
    date: "March 2, 2021",
    points: [
      "Incorporates changes from February 25th, 2021 update to Axure Cloud",
      "Updated RP9 Generator to 3723",
    ],
  },
  {
    title: "Axure Cloud Update: February 25, 2021",
    points: [
      "Revamp to prototype discussions infrastructure and UI",
      "Discussion threads are now marked as read/unread individually",
      "Fixed comment post button obscuring below client’s visible window",
      "Fixed resizing of client window exiting comment pin mode",
      "Fixed inability to resolve user comments for RP8 projects",
      "Fixed problems logging in to mobile app when duplicate accounts exist",
      "Fixed Axure Cloud logo missing from notification emails",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Fixed signing out via discussions for SSO users",
      "Fixed Technical Admins inability to sign in using standard authentication",
      "Admin Settings panel is set to Technical Admins’ default state",
      "Fixed Technical Admins inability to manage Super Admin and Admin accounts",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.327",
    date: "February 1, 2021",
    points: [
      "Incorporates changes from January 28th, 2021 update to Axure Cloud",
      "Fixed issue with resetting password through command prompt",
      "Fixed freeze on creating a workspace when email server has wrong port",
      "Fixed SSL/non-SSL settings for email servers",
      "Fixed issue with loading pages in IE",
    ],
  },
  {
    title: "Axure Cloud Update: January 28, 2021",
    points: [
      "Added Workspace access requests for protected prototypes",
      "New Welcome to Axure Cloud email",
      "Added iPhone 12 models to artboard project size settings",
      "Allow muting notifications for Workspaces",
      "Added Workspace name change event to feed",
      "Fixed long Workspace names breaking Slack/MS Teams notifications",
      "Fixed encoding issues for user nick names",
      "Additional security enhancements",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Addition of Technical Admin user type",
      "Non-Workspace members @mentioned in Publish notes are now given guest subscription",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.326",
    date: "December 21, 2020",
    points: ["Addressed password validation issue for Active Directory using LDAP authentication"],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.325",
    date: "December 15, 2020",
    points: [
      "Incorporates changes from December 15, 2020 update to Axure Cloud",
      "Added increased flexibility for custom proxy settings",
      "Replaced &ldquo;HostHttpOnly&rdquo; setting with &ldquo;MatchProtocolMode&rdquo; setting",
    ],
  },
  {
    title: "Axure Cloud Update: December 15, 2020",
    points: [
      "Added Workspace access requests for protected prototypes",
      "Added ability to post comments as a guest user",
      "Added ability to @mention guest commenters",
      "Removed the ability to leave anonymous comments",
      "Added ability to mark a comment resolved with a reply",
      "UI improvements to commenting flow",
      "Improvements to Workspace invitation emails",
      "Changes to how default avatars are assigned",
      "No longer exposing full email addresses in prototype comment pane",
      "Added 50 user limit for Workspace invitations",
      "Fixed inability to re-send Workspace invitations",
      "Fixed including existing Workspace members from blocking valid invitations",
      "Fixed loading Feedback comments when nickname contains special characters",
      "Fixed sending deleted comment notification when replies are deleted",
      "Fixed scroll line obscuring error message when Workspace invitation fails",
      "Additional security enhancements and bug fixes",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: ["Clearer messaging when deactivated users attempt to sign in"],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.324",
    date: "November 13, 2020",
    points: ["Fixed unresponsiveness during large Team Project operations"],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.323",
    date: "October 22, 2020",
    points: [
      "Incorporates changes from October 21, 2020 update to Axure Cloud",
      "RP9 generator updated to 3717",
    ],
  },
  {
    title: "Axure Cloud Update: October 21, 2020",
    points: [
      "Added Guest Subscriptions",
      "Added ability to @mention Guests",
      "Feedback thread participants now receive reply notifications",
      "Enhanced Notification Center UI",
      "Removed Daily Summary Notification checkbox",
      "Added unsubscribe links to email notifications",
      "Added default user avatar images",
      "Allowing Feedback pin numbers to exceed 99",
      "Fixed deleted projects breaking Workspace feed after 30 days",
      "Fixed linking to Feedback comment from email",
      "Fixed page refresh when showing/hiding Feedback pins",
      "Fixed page refresh when canceling Feedback comment delete",
      "Fixed Delete Comment notification referring to New Comment",
      "Fixed Marked Resolved notification referring to New Comment",
      "Fixed Workspace Feed hyperlink click behavior and hover style",
      "Fixed responsiveness of emails viewed on desktop devices",
      "Fixed issues including qq email addresses in Workspace invites",
      "Fixed receiving emails for project updates with “Some” notification setting",
      "Fixed performance/crashing issues for projects with many resolved comments",
      "Security enhancements",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: ["Fixed SSO Logout URL verification issues"],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.322",
    date: "September 24, 2020",
    points: [
      "Incorporates changes from September 22, 2020 update to Axure Cloud",
      "Test email now uses full email pipeline",
      "Enhancements to Active Directory configuration screen",
    ],
  },
  {
    title: "Axure Cloud Update: September 22, 2020",
    points: [
      "Allow links in Feedback comments",
      "Enhanced detection of Cloud version updates",
      "Fixed showing active tabs when visiting Cloud from Axure RP",
      "Fixed http redirect after entering prototype password for public urls",
      "Fixed redirect to prototype password page when opening http RP8 projects from Cloud UI",
      "Removed default Publish Note message",
      "Improved error handling for websockets",
      "Security enhancements",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Allow setting SSO authentication for multiple users",
      "Allow SSO log in to create new Reviewer accounts",
      "Automatically selecting SSO authentication for new users when SSO configured",
      "Improved add user account flow",
      "Increased password scrutiny for System Admins on Axure Cloud for Business creation",
      "Fixed error signing in to Axure Cloud for Business using multiple accounts",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.321",
    date: "September 11, 2020",
    points: [
      "Incorporates changes from September 8, 2020 update to Axure Cloud",
      "Fixed resetting admin password via command prompt ",
      "RP9 generator updated to 3715",
      "RP8 generator updated to 3392",
    ],
  },
  {
    title: "Axure Cloud Update: September 8, 2020",
    points: [
      "Added Workspace Activity Feed",
      "Bug fixes",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.320",
    date: "August 28, 2020",
    points: ["Fixed accessing password protected prototypes for HTTP instances"],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.319",
    date: "August 3, 2020",
    points: [
      "Improvements to email notifications",
      "Security enhancements",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.318",
    date: "July 24, 2020",
    points: [
      "Incorporates changes from July 22, 2020 update to Axure Cloud",
      "Exposing OnPrem version to all users",
      "Added support for @mentioning and inviting “Administrator”",
    ],
  },
  {
    title: "Axure Cloud Update: July 22, 2020",
    points: [
      "Email notifications revamp",
      "Added “What’s New” section to the menu",
      "Allow for adding comment text to clipboard",
      "Added support for shadows in Inspect",
      "Feedback pin number now tracked across entire project",
      "Other general Feedback enhancements",
      "Using desktop notifications to alert user of Workspace invitations",
      "Added modified date to folders",
      "Improved messaging for when @mentions isn’t available",
      "Updated device list for Artboard projects",
      "Fixed error renaming Masters in Artboard projects",
      "Allow for folders to have the same name as projects",
      "Preventing anonymous Feedback users from marking comments as resolved",
      "Workspace invites come from inviter not from Workspace owner",
      "Improvements to Slack/MS Teams notifications",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Added support for batch inviting users to Workspaces",
      "Including invite message when inviting to Workspace via @mention",
      "Fixed issues with First Workspace after downgrading authors to reviewers",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.317",
    date: "July 7, 2020",
    points: [
      "Incorporates changes from July 1, 2020 update to Axure Cloud (except for workspace migration)",
      "Better logging for generation errors",
      "RP9 generator updated to 3704",
    ],
  },
  {
    title: "Axure Cloud Update: July 1, 2020",
    points: [
      "Added support for migrating Workspaces from Axure Cloud for Business",
      "Added last modified date column to folders",
      "Enabled download for Feedback screenshot tool for Edge browser in prototype player",
      "Default Cloud avatar changes with nickname",
      "Better detection when Workspace invitations accepted in other tabs",
      "Fixed desktop notifications not showing custom profile avatars",
      "Fixed comment replies updating user nicknames",
      "Fixed scrolling beyond bottom of artboard after flip animations",
      "Enabled multiple authentication methods for hubs",
      "Patched CSRF vulnerabilities",
      "Better enforcement of filetype restrictions for artboard uploads",
      "Better obscuring of user information in password reset tokens",
      "Added HttpOnly flag for cookies",
      "Various bug fixes and security enhancements",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Added support for migrating Workspaces from Axure Cloud",
      "Surfacing total license and used license count on Admin Page",
      "Added support for activating/disabling multiple accounts at once",
      "Enhancements to user management on Admin Page",
      "Restricted @mention options available to Reviewers",
      "Various bug fixes and security enhancements",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.316",
    date: "May 13, 2020",
    points: [
      "Incorporates changes from May 11, 2020 update to Axure Cloud",
      "Enhanced logging for project generation timeouts",
    ],
  },
  {
    title: "Axure Cloud Update: May 11, 2020",
    points: [
      "Added support for emojis in discussion comments and publish notes",
      "Added desktop notifications for @mentions",
      "Added support for @mentioning in publish notes",
      "Added Help and Feedback menu options",
      "Added Figma elements to cloud UI",
      'Enabled "Show Hotspots" for artboard projects in mobile player',
      "Exposed full discussion comments in mobile player",
      "Added fixes and enhancements to Leave Workspace flow",
      "Other bug fixes and enhancements",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.315",
    date: "April 14, 2020",
    points: [
      "Incorporates changes from April 9, 2020 update to Axure Cloud",
      "Fixed generator to handle gzip errors gracefully",
      "Added support for multiple Active Directories across different domains",
      "Added SkipAxShareHostCheck flag to support certain proxy configurations",
    ],
  },
  {
    title: "Axure Cloud Update: April 9, 2020",
    points: [
      "Added publish notes for non-team-projects and artboard projects",
      "Updated project History page",
      "Updated prototype password UI for new Advanced projects",
      "Added project Overview refresh when stale data detected",
      "Fixed deleting multiple screens from Build page",
      "Fixed bugs moving projects out of folders",
      "Fixed bugs with long breadcrumb chains",
      "Fixed bugs with @mentions/badging feature",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Prevented sending emails to deactivated users",
      "Fixed bugs with @mentions/badging feature",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.314",
    date: "March 27, 2020",
    points: [
      "Incorporates changes from March 25, 2020 update to Axure Cloud",
      "Custom ports now allowed for MySql database installation",
      'Fixed issues with "=" character in database passwords',
      "Fixed Axure Handwriting font not appearing correctly",
      "Database connection strings now hidden in logs",
    ],
  },
  {
    title: "Axure Cloud Update: March 25, 2020",
    points: [
      "Added @Mentioning feature for Discussions",
      "Added Workspace member sharing links",
      "Added Auto (Web) platform option for Basic Projects",
      "Clearer active state for current Workspace",
      "Discussion comment counts surfaced on folders",
      "New responsive prototype password page",
      "Fixed Opacity CSS setting for Inspect of Basic Projects",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: ["Fixed deactivated users still appearing as Workspace members"],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.313",
    date: "February 28, 2020",
    points: [
      "Incorporates changes from February 26, 2020 update to Axure Cloud",
      "Improved formatting for OnPrem logs",
      "Fixed freezing issue with improperly entered email server credentials",
    ],
  },
  {
    title: "Axure Cloud Update: February 26, 2020",
    points: [
      "Added ability to favorite Workspaces",
      "Added ability to archive Workspaces",
      "Added reCAPTCHA for inviting users to Workspaces with optional message",
      "Added email chipping to new Workspace creation flow",
      "Order of artboards is maintained better when uploading to Cloud",
      "Better differentiation between pending and accepted Workspace members",
      "Better differentiation between shared and unshared Workspaces",
      "Fixed screenshot plugin installation dialog opening behind other elements",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Added email chipping to account creation for Sys-Admins/Admins",
      "Sys-Admins/Admins can toggle off non-member workspaces when moving, adding notifications, and exporting artboards",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.312",
    date: "February 7, 2020",
    points: [
      "Incorporates changes from February 5, 2020 update to Axure Cloud",
      "Added ability to disable Google fonts requests",
      "Fixed logging in to feedback with Active Directory/LDAP",
    ],
  },
  {
    title: "Axure Cloud Update: February 5, 2020",
    points: [
      'Renamed "My Projects" to "Private Workspace"',
      "Consolidated workspace overflow menu",
      "Standard workspace created for new users",
      "Workspace invitation emails link directly to pending workspaces",
      "Renaming workspaces will rename for all members",
      "Revamped workspace management workflow",
      "Further improvements to artboard upload status dialog",
      "Fixed bugs resulting in unnecessary comment badging",
      "Fixed issues logging in to Axure Cloud for long existing accounts",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Workspace invitations can now be accepted or declined",
      "Users can now leave workspaces from the workspace settings menu",
      "Admins and System Admins can see workspace owners by hovering over workspace names in the left sidebar",
      "Admins and System Admins can now join workspaces directly from the workspace settings menu",
      "Fixed sending notifications to Admins and System Admins for workspaces they haven't joined",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.310",
    date: "January 21, 2020",
    points: [
      "Incorporates changes from January 15, 2020 update to Axure Cloud",
      "Added support for LDAPS authentication",
      "Fixed authentication with Active Directory/LDAP via feedback",
      "Fixed domain field for Active Directory setup not working",
      "Fixed requiring password changes on login for Active Directory and LDAP environments",
      "Changes to SameSite Cookie settings to prepare for increased browser security updates",
    ],
  },
  {
    title: "Axure Cloud Update: January 15, 2020",
    points: [
      "Added badge notifications for discussion comments",
      "Improved artboard upload status dialog",
      "Added ability to modify screen order from project Overview page",
      "Fixed browser freezing when inspecting artboards with large assets",
      "Added banner for Internet Explorer users suggesting use of other browsers",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.309",
    date: "December 20, 2019",
    points: [
      "Incorporates changes from December 11, 2019 update to Axure Cloud",
      "Fixed errors preventing service start up after updates",
      "Fixed redirecting to localhost when visiting Axure Cloud for Business externally when there are database issue",
      "Fixed issues deleting artboard projects",
    ],
  },
  {
    title: "Axure Cloud Update: December 11, 2019",
    points: [
      "Enhancements to Discussions",
      "Enhancements to Cloud Project management menu",
      "Enhancements to Read Only mode behavior",
      "Fixed bug sending daily digest emails to former workspace members",
      "Fixed errors using Internet Explorer",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: ["Fixed crowding and bugs in Workspace invite dialog"],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.307",
    date: "November 6, 2019",
    points: [
      "Incorporates changes from October 29, 2019 update to Axure Cloud",
      "Enforcing strict passwords for system administrators",
      "Enhancements to manual install options",
      "Support for host header verification",
    ],
  },
  {
    title: "Axure Cloud Update: October 29, 2019",
    points: [
      "Enhancements to artboard project previewing",
      "Fixed generation failures for RP projects with common special characters in name",
      "Optimizations to artboard project asset download from Inspect",
      "Fixed connection errors editing MS Teams notifications",
      "Enhanced security against script injection vulnerabilities",
      "Updates to workspace invitation emails",
      "Further bug fixes",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Fixed missing SSO error pages",
      "Implemented remembering visible workspace setting between sessions",
      "Fixed redirect with alternate SSO login path",
      "Fix for unnecessary re-auths to SSO enabled instances",
      "Further bug fixes",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.306",
    date: "October 10, 2019",
    points: ["Fixed bug with differential generation for team projects"],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.305",
    date: "September 24, 2019",
    points: [
      "Incorporates changes from September 18, 2019 update to Axure Cloud",
      "Better error messaging for database connection issues",
      "Added ability to block setup routes when ACB update requires database schema change",
      "Fixed bugs using Internet Explorer during ACB configuration",
    ],
  },
  {
    title: "Axure Cloud Update: September 18, 2019",
    points: [
      "New configuration options for email notifications",
      "Fixed needing prototype password when clicking from Axure Cloud UI",
      "Fixed closing dialogs accidentally when selecting text",
      "Fixed loading team libraries as viewer only",
      "Bug fixes and security enhancements",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      "Fixed redirect with SSO login",
      "Increased password requirements:",
      "Must be at least 8 characters long",
      "Must contain at least 1 number",
      "Must contain both upper and lowercase letters",
      "Must not contain 3 or more sequential or repeating characters (<code>123</code>, <code>AAA</code>)",
    ],
  },
  {
    title: "Axure Cloud for Business On Premises 2.0.0.304",
    date: "August 16, 2019",
    points: [
      "Incorporates changes from August 15, 2019 update to Axure Cloud",
      `For servers that were upgraded from Axure Share Enterprise (Version 8), the following settings have been moved
    from the database into customsettings.json:`,
      "Web app domain name/URL",
      "SSL",
      "Max file size for uploads",
    ],
  },
  {
    title: "Axure Cloud Update: August 15, 2019",
    points: [
      "Artboard project screens can now be deleted from the project's Overview page.",
      "Performance enhancements and bug fixes.",
    ],
  },
  {
    title: "Axure Cloud for Business",
    isSubtitle: true,
    points: [
      `Axure Cloud For Business now supports
    <a href="https://docs.axure.com/axure-cloud/basics/notifications/#slack">Slack Notifications</a>.`,
      "Author accounts can now create workspaces and invite other users to them.",
      `Administrator accounts are no longer automatically added to every workspace. Instead, they can view every
     workspace and self-invite as needed.`,
    ],
  },
  {
    title: "Axure Cloud for Business On-Premises 2.0.0.302",
    date: "July 15, 2019",
    points: [
      `Incorporates changes from public Axure Cloud and adds support for Axure RP 9 files.<br>
    Contact your Axure account representative or
    <a href="https://www.axure.cloud/onprem-contact/">complete this form to request more information</a>.`,
    ],
  },
  {
    title: "The New Axure Cloud is now available!",
    date: "April 25, 2019",
    points: [
      "Find out <a href='https://www.axure.com/blog/welcome-to-the-new-axure#axure-cloud'>what's new in Axure Cloud</a>",
    ],
  },
  {
    title: "Axure Share Enterprise (On-Premises 8)",
    isSupertitle: true,
  },
  {
    title: "Version 1.0.0.214",
    date: "January 24, 2020",
    points: ["Changes to SameSite Cookie settings to prepare for increased browser security updates"],
  },
  {
    title: "Version 1.0.0.207",
    points: [
      "Fixes to support MySQL 8.0",
      "TLS 1.2 Fixes",
    ],
  },
  {
    title: "Version 1.0.0.206",
    points: [
      "Fixes for FIPS Compliance",
      "Support for LDAP",
      "Improvements to simplify configuration",
    ],
  },
  {
    title: "Version 1.0.0.195",
    points: [
      "SAML Improvements",
      "Fixes for generation issues on low-memory machines",
    ],
  },
  {
    title: "Version 1.0.0.191",
    points: [
      "Incorporates AxShare redesign",
      "8.1 features",
      "Windows Server 2016 fixes",
      "non-ssl SMTP emailer",
    ],
  },
  {
    title: "Version 1.0.0.186",
    points: [
      "Update to support Axure RP 8.1 Team Project features",
      "Bug fixes",
    ],
  },
  {
    title: "Version 1.0.0.176",
    points: [
      "added support for AxShareApp",
      "Merge latest AxShare Cloud changes",
      "allowed changing max file size or generation timeout",
      "Generator 3321",
    ],
  },
  {
    title: "Version 1.0.0.171",
    points: [
      "Added ability to see the previous version of the prototype while the new team project commit is generating",
      "Merge AxShare Cloud codebase",
    ],
  },
  {
    title: "Version 1.0.0.168",
    points: ["Reset account lockout when Administrator sets a user password"],
  },
  {
    title: "Version 1.0.0.167",
    points: [
      "Added Notifications for:",
      "Versions (New, Edit)",
      "Projects (New, Edit)",
      "Fixed issue with subscribing to notifications from Admin accounts (Super Admins worked correctly)",
      "Changed the cleanup algorithm to recover space from deleted prototypes much more aggressively",
      "Fixed issues setting values with an @ symbol (particularly notification sender email address) on MySQL databases",
      "Added a custom error page to alert users when the application cannot connect to the database",
      "Fixed issue installing on machines with cultures where the Local Service account was localized",
      "Various UI enhancements in the Axure Share Manager",
      "Various usability fixes for Axure Share",
    ],
  },
  {
    title: "Version 1.0.0.159",
    points: ["Fixed errors when uploading larger files in memory constrained environments"],
  },
  {
    title: "Version 1.0.0.153",
    points: ["Fix for issues with Windows 2012 R2 not correctly installing IIS"],
  },
  {
    title: "Version 1.0.0.151",
    points: ["Initial RTM Release"],
  },
];

export default Vue.extend({
  data() {
    return {
      releases,
    };
  },
});
