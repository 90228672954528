import { MutationTree } from "@/store/typed";

import { MutationPayloadMap, MutationTypes } from "./mutationTypes";
import { AxShareMigration, MigrationSteps } from "./state";

const mutations: MutationTree<AxShareMigration, MutationPayloadMap> = {
  [MutationTypes.MigrateWorkspaces](state, { migrate }) {
    if (!migrate) {
      state.step = MigrationSteps.Idle;
      state.inProgress = false;
      clearState(state);
      return;
    }
    state.step = MigrationSteps.GetStarted;
    state.inProgress = migrate;
  },

  [MutationTypes.Restart](state) {
    clearState(state);
    state.step = MigrationSteps.GetStarted;
  },

  [MutationTypes.SetStep](state, { step }) {
    setStep(state, step);
  },

  [MutationTypes.SetFailedStep](state, { step }) {
    state.failedStep = state.step;
    setStep(state, step);
  },

  [MutationTypes.SelectSubInstance](state, { domainPrefix }) {
    state.domainPrefix = domainPrefix;
  },

  [MutationTypes.SetMigrationResult](state, { result }) {
    state.userActivationRequired = result.UserActivationRequired;
    const superAdmin = result.SystemAdmins[0];
    if (superAdmin) {
      state.superAdmin = superAdmin;
    }
  },

  [MutationTypes.GoBack](state) {
    setStep(state, state.step - 1);
  },

  [MutationTypes.GoForward](state) {
    setStep(state, state.step + 1);
  },

  [MutationTypes.SetSessionToken](state, { migrationId, token }) {
    state.migrationId = migrationId;
    state.sessionToken = token;
  },

  [MutationTypes.SelectWorkspaces](state, { workspaces }) {
    state.workspacesToMigrate = [...workspaces];
  },

  [MutationTypes.SelectUsers](state, { users }) {
    state.usersToMigrate = [...users];
  },

  [MutationTypes.ConfirmMigration](state) {
    state.step = MigrationSteps.Confirmation;
  },
};

function setStep(state: AxShareMigration, step: MigrationSteps) {
  state.step = step;
  if (state.step === MigrationSteps.GetStarted) {
    clearState(state);
  }
}

function clearState(state: AxShareMigration) {
  state.migrationId = "";
  state.sessionToken = "";
  state.domainPrefix = "";
  state.usersToMigrate = [];
  state.workspacesToMigrate = [];
  state.failedStep = undefined;
}

export default mutations;
