import { AxShareCurrentUserInfo } from "@shared/models";
import { Payload, PayloadMap } from "@/store/typed";

export enum ActionTypes {
  GetAdminInfo = "[Admin] Get Admin Info",
  GetAdminUserInfo = "[Admin] Get Admin User Info",
  SetAdminUserInfo = "[Admin] Set Admin User Info",
  GetAdminSubInstanceInfo = "[Admin] Get Subinstance Info",
}

export class GetAdminInfo implements Payload {
  public static readonly type = ActionTypes.GetAdminInfo;
  public readonly type = GetAdminInfo.type;
}

export class GetAdminUserInfo implements Payload {
  public static readonly type = ActionTypes.GetAdminUserInfo;
  public readonly type = GetAdminUserInfo.type;
}

export class SetAdminUserInfo implements Payload {
  public readonly type = ActionTypes.SetAdminUserInfo;
  constructor(public userInfo: AxShareCurrentUserInfo | undefined) {}
}

export class GetAdminSubInstanceInfo implements Payload {
  public static readonly type = ActionTypes.GetAdminSubInstanceInfo;
  public readonly type = GetAdminSubInstanceInfo.type;
}

export interface ActionPayloadMap extends PayloadMap<ActionTypes> {
  [ActionTypes.GetAdminInfo]: GetAdminInfo;
  [ActionTypes.SetAdminUserInfo]: SetAdminUserInfo;
  [ActionTypes.GetAdminUserInfo]: GetAdminUserInfo;
  [ActionTypes.GetAdminSubInstanceInfo]: GetAdminSubInstanceInfo;
}
