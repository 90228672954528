import { param } from "jquery";
import { RouteConfig } from "vue-router";

import { FilesystemNodeShortcut } from "@/common/fs";
import plugins from "@/common/inspect/plugins";
import { getHashVarString } from "@/common/lib";
import { ROUTES } from "@/router";
import { getFirstPage } from "@/services/project.service";
import store from "@/store";

const Expo = () => import(/* webpackChunkName: "expo" */ "@/pages/Expo.vue");
const PageDeleted = () => import(/* webpackChunkName: "expo" */ "@/pages/ProjectPageDeleted.vue");

// prettier-ignore
export type Keys =
  | "expo.show"
  | "expo.preview"
  | "expo.inspect"
  | "expo.build"
  | "expo.pageDeleted"
  | "expo.build.all";

const resolveProps = () => ({
  project: store.getters.current.node,
});

const routes: { readonly [R in Keys]: RouteConfig } = {
  "expo.show": {
    path: "show",
    name: "expo.show",
    props: resolveProps,
    async beforeEnter(to, __, next) {
      // Used to redirect to member link if Share Link is disabled.
      // Here, using parameters from hash, we determine which page in the cloud to redirect to,
      // Also if there is no page id in the link parameters, then we try to get the id from the store,
      // If there is no page id in the store, we make a request to the server.
      // If there is no page Id, then we redirect to the overview page.
      const { shortcut } = to.params;
      const gids = getHashVarString(window.location.href, "g");

      const name = gids.includes(plugins.handoff.gid.toString())
        ? ROUTES["expo.inspect"].name : ROUTES["expo.preview"].name;

      const screen = getHashVarString(window.location.href, "id");
      const page = getHashVarString(window.location.href, "p");
      let startPage = screen || page;

      if (!startPage) {
        const shortcuts = store.getters.shortcuts;
        const project = shortcuts[shortcut] as FilesystemNodeShortcut;
        if (project) {
          if (project.isExpoProject) {
            const { artboardsOrder } = store.state.expo.projects[shortcut.toLowerCase()];
            if (artboardsOrder.length > 0) startPage = artboardsOrder[0];
          } else {
            const { fs } = store.state;
            const firstPageId = fs.firstPagesMap[project.id]?.Id;
            if (firstPageId) {
              startPage = firstPageId;
            } else {
              const firstPage = await getFirstPage(shortcut);
              if (firstPage && firstPage.Id) startPage = firstPage.Id;
            }
          }
        }
      }

      if (!startPage || getHashVarString(window.location.href, "open-share-dialog") === "true") {
        return next({ name: ROUTES["project.overview"].name, params: { shortcut }, hash: to.hash });
      }
      return next({ name, params: { screen: startPage, shortcut }, hash: to.hash });
    },
  },
  "expo.preview": {
    path: "preview/:screen",
    name: "expo.preview",
    component: Expo,
    props: resolveProps,
    meta: {
      headerMinimized: true,
    },
  },
  "expo.build": {
    path: "build/:screen",
    name: "expo.build",
    component: Expo,
    props: resolveProps,
    meta: {
      headerMinimized: true,
    },
    beforeEnter(to, __, next) {
      if (disableBuild()) {
        return next({
          name: ROUTES["project.overview"].name,
          params: {
            shortcut: to.params.shortcut,
          },
        });
      }

      return next();
    },
  },
  "expo.build.all": {
    path: "build",
    name: "expo.build.all",
    component: Expo,
    props: resolveProps,
    meta: {
      headerMinimized: true,
    },
    beforeEnter(to, __, next) {
      if (disableBuild()) {
        return next({
          name: ROUTES["project.overview"].name,
          params: {
            shortcut: to.params.shortcut,
          },
        });
      }

      const location = resolveBuildLocation(to.params.shortcut);
      if (location) {
        next(location);
      } else {
        next();
      }
    },
  },
  "expo.inspect": {
    path: "inspect/:screen",
    name: "expo.inspect",
    component: Expo,
    props: resolveProps,
    meta: {
      headerMinimized: true,
    },
  },
  "expo.pageDeleted": {
    path: "pageDeleted",
    name: "expo.pageDeleted",
    component: PageDeleted,
    props: resolveProps,
  },
};

function disableBuild(): boolean {
  return store.getters.enterpriseLicenseCheckFailed;
}

export function resolveBuildLocation(shortcut: string) {
  const { artboardsOrder } = store.state.expo.projects[shortcut.toLowerCase()];
  const first = artboardsOrder[0];
  if (first) {
    return {
      name: routes["expo.build"].name,
      params: { screen: first },
    };
  }
}

const config = [
  routes["expo.show"],
  routes["expo.preview"],
  routes["expo.build"],
  routes["expo.build.all"],
  routes["expo.inspect"],
  routes["expo.pageDeleted"],
];

export default {
  routes,
  config,
};
