
import { ValidationResult, PropertyValidator, ValidationContext } from "lakmus";
import Vue, { VueConstructor } from "vue";
import { PropOptions } from "vue/types/options";

import { AccountRowJs, SsoResponse } from "@shared/models";

import { FilesystemNodeWorkspace, isWorkspaceNode, ManageUsersInviteModelValidator } from "@/common/fs";
import { asError } from "@/common/lib";
import { workspaceMembersMixin } from "@/mixins/WorkspaceMembers";
import { getSubinstanceAccounts, shareOrInviteToFileSystem } from "@/services/fs.service";
import { AccessLevels, ManageUsersModel, UserProfile } from "@/services/models/account";
import { ManageUsersInviteModel } from "@/services/models/filesystem";
import { Fetch } from "@/store/fs/actionTypes";
import { AxShare } from "@/store/state";
import { AxShareUser } from "@/store/user/state";

import AxButton from "@/components/AxButton.vue";
import AxConfigureWorkspaceSecurity from "@/components/AxConfigureWorkspaceSecurity.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormErrors from "@/components/AxFormErrors.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxInput from "@/components/AxInput.vue";
import AxInputEmails from "@/components/AxInputEmails.vue";
import AxManageUsersSelect from "@/components/AxManageUsers/AxManageUsersSelect.vue";
import AxReCaptcha from "@/components/AxReCaptcha.vue";
import { Submit } from "@/components/types/AxForm";

type Mixins = InstanceType<typeof workspaceMembersMixin>;

const workspaceProp: PropOptions<FilesystemNodeWorkspace> = {
  type: Object,
  required: false,
  validator(item) {
    return isWorkspaceNode(item);
  },
};

export class StringListValidator extends PropertyValidator {
  constructor(private readonly list: string[],
      private readonly message?: (value: string) => string,
      private readonly invert: boolean = false,
      errorMessage?: string) {
    super(errorMessage);
  }

  public isValid(context: ValidationContext): boolean {
    const value = context.propertyValue as string;

    if (typeof value !== "string") {
      return false;
    }

    return this.list.includes(value) ? !this.invert : this.invert;
  }

  public getErrorMessage(ctx: ValidationContext) {
    return !this.message ? `'${ctx.propertyValue}' is not a valid email address` : this.message(ctx.propertyValue);
  }
}

function createListValidator(list: string[], message: (value: string) => string, invert = false) {
  return new StringListValidator(list, message, invert);
}

export default (Vue as VueConstructor<Vue & Mixins>).extend({
  components: {
    AxButton,
    AxConfigureWorkspaceSecurity,
    AxForm,
    AxFormGroup,
    AxManageUsersSelect,
    AxInput,
    AxInputEmails,
    AxFormErrors,
    AxReCaptcha,
  },

  mixins: [workspaceMembersMixin],

  props: {
    workspace: workspaceProp,
    autofocus: {
      type: Boolean,
      default: false,
    },
    customSubmit: {
      type: Function,
      default: undefined,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    forceAction: {
      type: Boolean,
      default: false,
    },
    existingUsersTitle: {
      type: String,
      default: "existing workspace users:",
    },
  },

  data() {
    const usersInvite: ManageUsersInviteModel = {
      userEmails: [],
      inviteMessage: "",
      viewerOnly: false,
    };

    const validators: PropertyValidator[] = [];
    const model: ManageUsersModel = {
      users: [],
      usersInvite,
      userOnly: this.workspace && !!this.workspace.userOnly,
      httpsOnly: this.workspace && !!this.workspace.httpsOnly,
    };
    return {
      model,
      validators,
      userEmails: [] as string[],
      inviteModel: undefined as ManageUsersInviteModel | undefined,
      validationErrors: [] as string[],
      validator: ManageUsersInviteModelValidator,
      inputStarted: false,
      menuOpened: false,
      subInstanceAccounts: [] as AccountRowJs[],
      captchaReady: false,
      submitting: false,
      isMessageEmpty: true,
      usersLoaded: false,
      pasting: false,
    };
  },

  computed: {
    isDefaultWorkspace(): boolean {
      return this.workspace && this.workspace.isDefault;
    },

    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    currentUser(): AxShareUser {
      const { user } = this.$store.state as AxShare;
      return user;
    },

    sitekey(): string {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null ? axShareConfig.GoogleReCaptchaSiteKey : "";
    },

    useReCaptcha(): boolean {
      return !this.isMessageEmpty && !this.isSubInstance && !!this.sitekey;
    },
    formLoading(): boolean {
      if (this.useReCaptcha) {
        return this.submitting || !this.captchaReady;
      }
      return this.submitting;
    },
  },

  async mounted() {
    await this.init();
  },

  methods: {
    onMessageInput(text: string) {
      this.isMessageEmpty = !text;
    },

    async init() {
      if (this.isSubInstance) {
        await this.updateUsers();
      }
    },

    async updateUsers() {
      if (this.isSubInstance) {
        this.subInstanceAccounts = (await getSubinstanceAccounts()).Items;
        const { userInfo } = this.currentUser;

        // Get the workspace owner's email, since if current user is workspace owner,
        // the ownerEmail is null and we grab it from userInfo
        let ownerEmail: string | undefined;
        if (this.workspace) {
          if (this.workspace.isOwner && userInfo) {
            ownerEmail = userInfo.userEmail;
          } else {
            ownerEmail = this.workspace.ownerEmail;
          }
        }

        const filteredAccounts = this.subInstanceAccounts.filter(
          account => !account.IsDeactivated // exclude deactivated account
            && !(account.Email === ownerEmail) // exclude owner account
            && !(this.workspace && this.workspace.users.some(shared => shared.user.userId === account.Id)), // exclude already invited
        );

        this.model.users = filteredAccounts.map(account => ({
          user: this.getUser(account) as SsoResponse,
          profile: this.getProfile(account) as UserProfile,
          isViewer: account.Level === AccessLevels.Reviewer || account.Level === AccessLevels.TechnicalAdmin,
          Level: account.Level,
          isViewerAccount: account.Level === AccessLevels.Reviewer || account.Level === AccessLevels.TechnicalAdmin,
        }));

        const canBeInvitedUsers = this.model.users.map(u => u.user.userEmail);
        const sharedUsers = this.sharedWith.map(u => u.user.userEmail);
        const allUsers = canBeInvitedUsers.concat(sharedUsers);
        this.validators.push(
          createListValidator(sharedUsers, email => `'${email}' already invited.`, true),
          createListValidator(allUsers, email => `'${email}' does not exist, contact your system admin.`),
        );

        this.usersLoaded = true;
      }
    },

    getUser(account: AccountRowJs) {
      const user: Partial<SsoResponse> = {
        userId: account.Id,
        userEmail: account.Email,
      };
      return user;
    },

    getProfile(account: AccountRowJs) {
      const userProfile: Partial<UserProfile> = {
        Name: account.Email,
      };
      return userProfile;
    },

    onValidationError(validationResult: ValidationResult) {
      this.clearErrors();
      this.validationErrors.push(...validationResult.errors.map(error => error.errorMessage));
    },

    onError(errorMessage: string) {
      this.clearErrors();
      this.validationErrors.push(errorMessage);
    },

    clearErrors() {
      if (this.pasting) return;
      this.validationErrors = [];
    },

    async submit({ values }: Submit<ManageUsersModel>) {
      this.submitting = true;
      if (values.usersInvite && values.usersInvite.userEmails) {
        const userEmails = values.usersInvite.userEmails;
        this.inviteModel = {
          userEmails: Array.isArray(userEmails) ? userEmails.join(",") : userEmails,
          inviteMessage: values.usersInvite.inviteMessage,
          viewerOnly: values.usersInvite.viewerOnly,
          filesystemId: this.workspace.id,
          ignoreAccepted: true,
          ignorePending: true,
        };
        if (!this.useReCaptcha) {
          await this.submitAction();
        } else {
          await (this.$refs.recaptcha as any).execute();
        }
      } else {
        this.submitting = false;
      }
    },

    cancel() {
      this.$emit("cancel");
    },

    async submitAction(recaptchaToken: string | undefined = undefined) {
      this.clearErrors();
      const { customSubmit } = this;
      const model = this.inviteModel;
      if (model) {
        try {
          if (customSubmit) {
            return await customSubmit(model, recaptchaToken);
          }

          await shareOrInviteToFileSystem(model, recaptchaToken);
          await this.$store.dispatch(new Fetch(this.workspace));
          this.$emit("submit");
        } catch (error) {
          this.validationErrors.push(asError(error).message);
        } finally {
          this.submitting = false;
        }
      }
    },

    onCaptchaReadyChanging(ready: boolean) {
      this.captchaReady = ready;
    },

    onBeforePaste() {
      this.clearErrors();
      this.pasting = true;
    },

    onAfterPaste() {
      this.pasting = false;
    },
  },
});
