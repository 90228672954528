
import Vue from "vue";

import AxButton from "@/components/AxButton.vue";
import WelcomeLayout from "@/layouts/WelcomeLayout.vue";
import { AxShare } from "@/store/state";
import { ROUTES } from "@/router";

export default Vue.extend({
  components: {
    AxButton,
    WelcomeLayout,
  },

  data() {
    return {
      fsRoute: { name: ROUTES.fs.name },
      loginRoute: { name: ROUTES.login.name },
    };
  },

  computed: {
    isAuthed(): boolean {
      let isAuthed = false;
      const { accountService } = this.$store.state as AxShare;
      if (accountService) {
        isAuthed = accountService().IsAuthed;
      }
      return isAuthed;
    },
  },
});
