
import Vue from "vue";
import VueRouter from "vue-router";

import { AxShareConfigModel } from "@shared/models";

import {
  AnalyticsClickEvents, resolveAfterAuthRedirect, redirectParamName, wellKnownRoutes,
} from "@/common/axshare";
import { accountServiceUnavailable } from "@/common/axshare/messages";
import { hashPassword } from "@/common/utils";
import AccountService from "@/services/account.service";
import { DoLoginModel } from "@/services/models/account";
import { AxShare } from "@/store/state";

import { Authenticate } from "../store/actionTypes";

import AxButton from "@/components/AxButton.vue";
import AxForgotPassword from "@/components/AxForgotPassword.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxFormSuccess from "@/components/AxFormSuccess.vue";
import AxInput from "@/components/AxInput.vue";
import AxSamlLogin from "@/components/AxSamlLogin.vue";
import { Submit } from "@/components/types/AxForm";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

interface Model {
  email: string;
  password: string;
  staySignedIn: boolean;
}

export default Vue.extend({
  components: {
    AxButton,
    AxForm,
    AxFormGroup,
    AxFormSuccess,
    AxForgotPassword,
    AxInput,
    AxSamlLogin,
  },

  props: {
    signupRoute: {
      type: Object,
      required: true,
    },

    hideHeader: {
      type: Boolean,
      default: false,
    },
  },

  metaInfo: {
    titleTemplate: "Sign in - %s",
  },

  data() {
    return {
      AnalyticsClickEvents,
      model: {
        email: "",
        password: "",
        staySignedIn: true,
      },

      errors: [] as string[],
      successMessages: [] as string[],
    };
  },

  computed: {
    accountService(): AccountService | undefined {
      const { accountService } = this.$store.state as AxShare;
      if (accountService) {
        return accountService();
      }
      return undefined;
    },

    showSaml(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return !!axShareConfig && !!axShareConfig.SamlUrl && axShareConfig.LdapAuthenticationEnabled !== true;
    },

    showSignup(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      if (axShareConfig !== null && axShareConfig.IsSubInstance === false) return true;
      return false;
    },

    axShareConfig(): AxShareConfigModel | null {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig;
    },

    ldapEnabled(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return !!axShareConfig && axShareConfig.LdapAuthenticationEnabled === true;
    },

    userNamePlaceholder(): string {
      return this.ldapEnabled ? "Username or Email" : "Email";
    },

    userNameInputType(): string {
      return this.ldapEnabled ? "text" : "email";
    },
  },

  methods: {
    forgotPasswordErrors(errors: string) {
      this.setStatusMessages({ errors });
    },

    forgotPasswordSuccess(success: string) {
      this.setStatusMessages({ success });
    },

    setStatusMessages({ success = [], errors = [] }: { success?: string | string[]; errors?: string | string[] }) {
      const successMessages = Array.isArray(success) ? success : [success];
      const errorMessages = Array.isArray(errors) ? errors : [errors];
      this.successMessages = successMessages;
      this.errors = errorMessages;
    },

    clearStatusMessages() {
      this.setStatusMessages({ success: [], errors: [] });
    },

    async submit({ values }: Submit<Model>) {
      this.clearStatusMessages();
      const { axShareConfig, accountService } = this;
      if (!accountService) {
        throw new Error(accountServiceUnavailable);
      }
      const { email, password, staySignedIn } = values;

      const onPremMode = !!axShareConfig && axShareConfig.AxShareOnPrem === true;
      const onPremLdapAuthentication = !!axShareConfig && axShareConfig.LdapAuthenticationEnabled === true;
      const useClearPassword = onPremLdapAuthentication;
      const loginPassword = onPremMode && useClearPassword ? password : hashPassword(password);
      const passwordBlank = !password;
      const altPassword = onPremLdapAuthentication ? password : "";

      const model: DoLoginModel = {
        loginEmail: email.trim(), // trim input value, according html standard, related to FF bug
        loginPassword,
        altPassword,
        staySignedIn,
        clearPass: useClearPassword,
        accountService: true,
        passwordBlank,
      };

      const redirectParam = this.$route.query[redirectParamName];
      const redirect = Array.isArray(redirectParam) ? redirectParam[0] : redirectParam;
      if (redirect) {
        const resolved = this.$router.resolve({
          name: wellKnownRoutes.login.name,
          query: this.$route.query,
        });
        model.redirect = encodeURIComponent(resolved.href);
      }

      await accountService.login(model);
      await this.$store.dispatch(new Authenticate());

      this.$emit("auth-success");

      const to = resolveAfterAuthRedirect(this.$route, axShareConfig?.AxShareHost);
      try {
        if (to) await this.$router.push(to);
      } catch (error) {
        if (!isNavigationFailure(error, NavigationFailureType.redirected)) {
          throw error;
        }
      }
    },
  },
});
