
import {
  createPopper, Instance, Modifier, Placement, PositioningStrategy,
} from "@popperjs/core";
import flip from "@popperjs/core/lib/modifiers/flip";
import preventOverflow from "@popperjs/core/lib/modifiers/preventOverflow";
import Vue from "vue";

import { sameWidth } from "@/common/axshare/popperjs";
import { directive as onClickAway } from "@/mixins/ClickAway";

import { prop } from "@/components/utils";

export default Vue.extend({
  directives: {
    onClickAway,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },

    closeOnContentClick: {
      type: Boolean,
      default: true,
    },

    right: {
      type: Boolean,
      default: false,
    },

    left: {
      type: Boolean,
      default: false,
    },

    top: {
      type: Boolean,
      default: false,
    },

    bottom: {
      type: Boolean,
      default: false,
    },

    middle: {
      type: Boolean,
      default: false,
    },

    skidding: {
      type: Number,
      default: 0,
    },

    distance: {
      type: Number,
      default: -3,
    },

    contentClass: {
      type: String,
      default: "",
    },

    overlay: {
      type: Boolean,
      default: false,
    },

    controlManually: {
      type: Boolean,
      default: false,
    },

    scrollToIndex: {
      type: Number,
      required: false,
      default: undefined,
    },

    itemHeight: {
      type: Number,
      required: false,
      default: undefined,
    },

    nativeStyled: {
      type: Boolean,
      default: false,
    },

    strategy: prop<PositioningStrategy>({
      required: false,
      default: undefined,
    }),

    activatorRef: {
      type: Object,
      required: false,
      default: undefined,
    },

    useActivatorWidth: {
      type: Boolean,
      default: false,
    },

    arrow: {
      type: Boolean,
      default: false,
    },

    dropMaxHeight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasDetached: false,
      popper: undefined as Instance | undefined,
    };
  },

  computed: {
    contentClassComputed(): Record<string, boolean> {
      const classes = {
        [`ax-menu__content ${this.contentClass}`.trim()]: true,
        "ax-menu__content--native-styled": this.nativeStyled,
      };
      return classes;
    },

    contentMaxHeight() {
      return this.nativeStyled ? 190 : 295;
    },

    contentStyleComputed(): Partial<CSSStyleDeclaration> {
      const styles: Partial<CSSStyleDeclaration> = {
      };

      if (!this.dropMaxHeight) {
        styles.maxHeight = `${this.contentMaxHeight}px`;
      }
      return styles;
    },

    placement(): Placement {
      if (this.top) {
        if (this.right) {
          return "top-end";
        }
        if (this.left) {
          return "top-start";
        }
        return "top";
      }

      if (this.bottom) {
        if (this.right) {
          return "bottom-end";
        }
        if (this.left) {
          return "bottom-start";
        }
        return "bottom";
      }

      if (this.left) {
        return "left";
      }

      if (this.right) {
        return "right";
      }

      return "bottom-end";
    },
  },

  watch: {
    value(val) {
      this.$emit("toggle", val);
      if (val) {
        this.activate();
      } else {
        this.deactivate();
      }
    },

    scrollToIndex(index) {
      if (this.itemHeight && index !== null && index !== undefined) {
        const content = this.$refs.content as HTMLElement | undefined;
        if (content) {
          const itemPosition = this.itemHeight * index;
          const oldScrollPosition = content.scrollTop;
          const offset = 2;
          if (itemPosition < oldScrollPosition) {
            content.scrollTop = itemPosition;
          } else if (itemPosition + this.itemHeight >= oldScrollPosition + this.contentMaxHeight) {
            content.scrollTop = itemPosition + this.itemHeight - this.contentMaxHeight + offset;
          }
        }
      }
    },
  },

  beforeDestroy() {
    if (this.hasDetached) {
      const content = this.$refs.content as HTMLElement | undefined;
      if (!content) return;

      try {
        if (this.popper) {
          this.popper.destroy();
          this.popper = undefined;
          return;
        }
      } catch (e) {
        console.log(e);
      }
    }
  },

  methods: {
    closeConditional(): boolean {
      if (this.controlManually) {
        return false;
      }
      return this.value && this.closeOnContentClick;
    },

    detach() {
      this.$nextTick(() => {
        if (!this.$refs.content) {
          return;
        }

        let activator = (this.activatorRef || this.$refs.activator) as Element | Vue | undefined;
        if (activator && !(activator instanceof Element)) {
          activator = activator.$el;
        }
        const content = this.$refs.content as HTMLElement | undefined;
        if (activator && content) {
          const modifiers: Partial<Modifier<any, any>>[] = [
            preventOverflow,
            flip,
            {
              name: "offset",
              options: {
                offset: [this.skidding, this.distance],
              },
            },
          ];
          if (this.useActivatorWidth) {
            modifiers.push(sameWidth);
          }

          this.popper = createPopper(activator, content, {
            placement: this.placement,
            strategy: this.strategy,
            modifiers,
          });
          this.hasDetached = true;
        }
      });
    },

    toggle(): void {
      const val = this.value;
      if (val) {
        this.deactivate();
      } else {
        this.activate();
      }
    },

    activate(): void {
      this.$emit("input", true);
      this.detach();
    },

    deactivate(): void {
      this.$emit("input", false);
    },

    contentClick(e: MouseEvent): void {
      e.stopPropagation();
      if (e.target && e.target instanceof Element && e.target.getAttribute("disabled")) {
        return;
      }
      if (this.closeOnContentClick) {
        this.deactivate();
      }
    },

    onClickAwayInclude() {
      return [this.$el];
    },
  },
});
