import { sha512 } from "sha.js";

import { Hotspot } from "@shared/expo";

export function normalizeHotspotMeasurements(hs: Hotspot) {
  // Rounds or floors the val based on traditional rounding
  function floorRound(val: number) {
    const integer = Math.floor(val);
    const decimal = val % 1;

    if (decimal < 0.5) {
      return integer;
    }
    return Math.round(val);
  }

  const normalized: Hotspot = {
    Dimensions: {
      Height: floorRound(hs.Dimensions.Height),
      Width: floorRound(hs.Dimensions.Width),
    },
    Start: {
      X: floorRound(hs.Start.X),
      Y: floorRound(hs.Start.Y),
    },
    End: {
      X: floorRound(hs.End.X),
      Y: floorRound(hs.End.Y),
    },
  };

  return normalized;
}

export function createUniqueTag(): string {
  const tag = Math.random()
    .toString()
    .substring(2)
    + Math.random()
      .toString()
      .substring(2)
    + Math.random()
      .toString()
      .substring(2)
    + Math.random()
      .toString()
      .substring(2);
  return tag;
}

export function hashPassword(toHash: string, salt: string = "axure") {
  return new sha512().update(`${salt}${toHash}`).digest("hex");
}

/* eslint-disable no-bitwise */
export function simpleHash(toHash: string): number {
  let hash = 0;
  if (toHash.length === 0) {
    return hash;
  }
  for (let i = 0; i < toHash.length; i++) {
    const char = toHash.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash &= hash;
  }
  return hash;
}
/* eslint-enable no-bitwise */

export function addParamsToFragment(hash: string, params: string) {
  const fragment = hash ? `${hash.substring(1)}&${params}` : params;
  const separatedParams = fragment.split("&");
  const uniqueParams = [...new Set(separatedParams)];
  return uniqueParams.join("&");
}
