
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import {
  FilesystemNodeType, FilesystemNodeTypes, ProjectType, ProjectTypes,
} from "@/common/fs";
import { fsRouterLocation } from "@/router/fs";

import AxCreateProjectRp from "@/components/AxCreateProjectRp.vue";
import AxCreateProjectSketch from "@/components/AxCreateProjectSketch.vue";

const nodeProp: PropOptions<FilesystemNodeTypes> = {
  type: Object,
  required: true,
};

export default Vue.extend({
  components: {
    AxCreateProjectRp,
    AxCreateProjectSketch,
  },

  props: {
    node: nodeProp,
  },

  data() {
    const data = {
      compact: true,
      ProjectType,
      projectType: "",
    };

    return data;
  },

  computed: {
    folderId(): string {
      if (this.node.type === FilesystemNodeType.Workspace) return this.node.rootFolderId;
      return this.node.id;
    },
  },

  methods: {
    modeChanged(projectType: ProjectTypes, compact: boolean) {
      this.projectType = projectType;
      this.compact = compact;
      this.$emit("modeChanged", { compact, projectType });
    },

    async submit() {
      this.cancel(true);
    },

    cancel(refresh: boolean = false) {
      this.$router.push(fsRouterLocation(this.folderId, { refresh: { contents: refresh } }));
    },
  },
});
