import { RouteConfig } from "vue-router";

import { ROUTES } from "@/router";
import store from "@/store";
import { AxShare } from "@/store/state";

const AdminAccounts = () => import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccounts.vue");
const AdminAccountsAdd = () => import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsAdd.vue");
const AdminAccountsSetAccess = () =>
  import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsSetAccess.vue");
const AdminAccountsDeactivate = () =>
  import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsDeactivate.vue");
const AdminAccountsActivate = () =>
  import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsActivate.vue");
const AdminAccountsActivateMulti = () =>
  import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsActivateMulti.vue");
const AdminAccountsSamlRemove = () =>
  import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsSamlRemove.vue");
const AdminAccountsSamlAdd = () =>
  import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsSamlAdd.vue");
const AdminAccountsChangeEmail = () =>
  import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsChangeEmail.vue");
const AdminAccountsChangePassword = () =>
  import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsChangePassword.vue");
const AxAdminAccountsSuperAdminChange = () =>
  import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminAccountsSuperAdminChange.vue");
const AdminEmail = () => import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminEmail.vue");
const AdminLicense = () => import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminLicense.vue");
const AdminInfo = () => import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminInfo.vue");
const AdminLdap = () => import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminLdap.vue");
const AdminSaml = () => import(/* webpackChunkName: "admin" */ "@/components/AxAdmin/AxAdminSaml.vue");
const AdminBase = () => import(/* webpackChunkName: "admin" */ "@/pages/Admin.vue");

export type Keys =
  | "admin"
  | "admin.accounts"
  | "admin.accounts.add"
  | "admin.accounts.access"
  | "admin.accounts.deactivate"
  | "admin.accounts.activate"
  | "admin.accounts.bulk.activate"
  | "admin.accounts.saml.remove"
  | "admin.accounts.saml.add"
  | "admin.accounts.email.change"
  | "admin.accounts.password.change"
  | "admin.accounts.superadmin.change"
  | "admin.saml"
  | "admin.ldap"
  | "admin.email"
  | "admin.license"
  | "admin.info";

const routes: { readonly [R in Keys]: RouteConfig } = {
  admin: {
    path: "/admin",
    name: "admin",
    component: AdminBase,
    beforeEnter(_, __, next) {
      const { axShareConfig } = store.state as AxShare;
      if (axShareConfig && axShareConfig.IsSubInstance) {
        return next();
      }
      return next(ROUTES.home);
    },
  },
  "admin.accounts": {
    path: "accounts",
    name: "Manage Accounts",
    component: AdminAccounts,
    props: true,
  },
  "admin.accounts.add": {
    path: "add",
    name: "admin.accounts.add",
    component: AdminAccountsAdd,
    props: true,
  },
  "admin.accounts.access": {
    path: "access",
    name: "admin.accounts.access",
    component: AdminAccountsSetAccess,
    props: true,
  },
  "admin.accounts.deactivate": {
    path: "deactivate",
    name: "admin.accounts.deactivate",
    component: AdminAccountsDeactivate,
    props: true,
  },
  "admin.accounts.activate": {
    path: "activate",
    name: "admin.accounts.activate",
    component: AdminAccountsActivate,
    props: true,
  },
  "admin.accounts.bulk.activate": {
    path: "bulk-activate",
    name: "admin.accounts.bulk.activate",
    component: AdminAccountsActivateMulti,
    props: true,
  },
  "admin.accounts.saml.remove": {
    path: "samlremove",
    name: "admin.accounts.saml.remove",
    component: AdminAccountsSamlRemove,
    props: true,
  },
  "admin.accounts.saml.add": {
    path: "samladd",
    name: "admin.accounts.saml.add",
    component: AdminAccountsSamlAdd,
    props: true,
  },
  "admin.accounts.email.change": {
    path: "changeemail",
    name: "admin.accounts.email.change",
    component: AdminAccountsChangeEmail,
    props: true,
  },
  "admin.accounts.password.change": {
    path: "changepassword",
    name: "admin.accounts.password.change",
    component: AdminAccountsChangePassword,
    props: true,
  },
  "admin.accounts.superadmin.change": {
    path: "changesuperadmin",
    name: "admin.accounts.superadmin.change",
    component: AxAdminAccountsSuperAdminChange,
    props: true,
  },
  "admin.saml": {
    path: "saml",
    name: "Single Sign On (SAML)",
    component: AdminSaml,
    props: true,
  },
  "admin.ldap": {
    path: "ldap",
    name: "Active Directory / LDAP",
    component: AdminLdap,
    props: true,
  },
  "admin.email": {
    path: "email",
    name: "Email",
    component: AdminEmail,
    props: true,
  },
  "admin.license": {
    path: "license",
    name: "License Key",
    component: AdminLicense,
    props: true,
  },
  "admin.info": {
    path: "info",
    name: "Information",
    component: AdminInfo,
    props: true,
  },
};

const config = [
  {
    ...routes.admin,
    redirect: routes["admin.accounts"],
    children: [
      {
        ...routes["admin.accounts"],
        children: [
          routes["admin.accounts.add"],
          routes["admin.accounts.access"],
          routes["admin.accounts.deactivate"],
          routes["admin.accounts.activate"],
          routes["admin.accounts.bulk.activate"],
          routes["admin.accounts.saml.remove"],
          routes["admin.accounts.saml.add"],
          routes["admin.accounts.email.change"],
          routes["admin.accounts.password.change"],
          routes["admin.accounts.superadmin.change"],
        ],
      },
      routes["admin.saml"],
      routes["admin.ldap"],
      routes["admin.email"],
      routes["admin.license"],
      routes["admin.info"],
    ],
  },
];

export default {
  routes,
  config,
};
