import { render, staticRenderFns } from "./AxShortcutModified.vue?vue&type=template&id=77f44868&scoped=true&"
import script from "./AxShortcutModified.vue?vue&type=script&lang=ts&"
export * from "./AxShortcutModified.vue?vue&type=script&lang=ts&"
import style0 from "./AxShortcutModified.vue?vue&type=style&index=0&id=77f44868&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f44868",
  null
  
)

export default component.exports