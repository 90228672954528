import axios, { AxiosProgressEvent, Canceler } from "axios";

import {
  ShortcutPagesResponse,
  PrototypeViewerParams,
  ShortcutJs,
  VarsJs,
  ShortcutNotificationStateJs,
  ShortcutRedirectCommentInfoJs,
  ExtendedProjectPage,
} from "@shared/models";

import { exec } from "@/services/api";
import { ChangeProjectInfo, ProjectCreateModel } from "@/services/models/project";
import { ResponseObject } from "@/services/models/responseObject";
import { server } from "@/services/server";
import { withNoCacheHeaders } from "@/services/utils/axios";
import { objectToFormData } from "@/services/utils/formData";

// eslint-disable-next-line no-empty-function
const noop = (..._: any[]) => { };

export function getProjectPages(shortcut: string) {
  return exec<ShortcutPagesResponse>(server.get(`/prototype/pages/${shortcut}`));
}

export function getFullCommentsState(shortcut: string) {
  return exec<ShortcutNotificationStateJs>(server.get(`/prototype/getfullcommentsstate/${shortcut}`));
}

export function getProjectInfo(shortcut: string) {
  return exec<ShortcutJs>(server.get(`/prototype/getprototypeinfo/${shortcut}`));
}

export function getPrototypeRedirectCommentInfo(shortcut: string) {
  return exec<ShortcutRedirectCommentInfoJs>(server.get(`/prototype/getprototyperedirectcommentinfo/${shortcut}`));
}

export function getPassword(shortcut: string) {
  return exec<VarsJs>(server.post(`/prototype/getpassword/${shortcut}`));
}

export function changeProjectInfo(
  shortcut: string,
  changedProjectInfo: Partial<ChangeProjectInfo>,
  executor: (cancel: Canceler) => void = noop,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) {
  const formData = objectToFormData(changedProjectInfo);
  return exec<ResponseObject>(
    server.post(`/prototype/changeprototypeinfo/${shortcut}`, formData, {
      cancelToken: new axios.CancelToken(executor),
      onUploadProgress,
    }),
  );
}

export function checkPrototypeNameValid(name: string, folderId: string) {
  const formData = objectToFormData({ name, folderId });
  return exec<ResponseObject>(server.post("/prototype/checklimitprototypenamevalid", formData));
}

export function createProject(
  model: ProjectCreateModel,
  executor: (cancel: Canceler) => void = noop,
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
) {
  const formData = objectToFormData(model);
  return exec<VarsJs>(
    server.post("/prototype/create", formData, {
      cancelToken: new axios.CancelToken(executor),
      onUploadProgress,
    }),
  );
}

export function createEmptyProject(model: ProjectCreateModel) {
  const formData = objectToFormData(model);
  return exec<VarsJs>(server.post("/prototype/createempty", formData));
}

export function cloneShortcut(shortcut: string, currentFolderId: string) {
  const formData = objectToFormData({ shortcut, currentFolderId });
  return exec<ResponseObject>(server.post("/prototype/cloneshortcut", formData));
}

export function toggleDiscussions(shortcut: string, feedbackEnabled: boolean) {
  const formData = objectToFormData({ feedbackEnabled });
  return exec<ResponseObject>(server.post(`/prototype/setfeedback/${shortcut}`, formData));
}

export function getViewerParams() {
  return exec<PrototypeViewerParams>(server.get("/prototype/getviewerparams", withNoCacheHeaders));
}

export function canUpload(folderId: string, isCreate: boolean) {
  return exec<ResponseObject>(
    server.get("/prototype/canupload", {
      params: {
        isCreate,
        folderId,
      },
    }),
    { ignoreResponseRedirectUrl: true },
  );
}

export function createPublishNote(shortcut: string, publishNote: string) {
  const formData = objectToFormData({ PublishNoteRichText: publishNote });
  return exec<ResponseObject>(server.post(`/prototype/createpublishnote/${shortcut}`, formData));
}

export function createPublishNotePlainText(shortcut: string, publishNote: string) {
  const formData = objectToFormData({ PublishNote: publishNote });
  return exec<ResponseObject>(server.post(`/prototype/createpublishnote/${shortcut}`, formData));
}

export function resetUnreadPublishNotesMentions(shortcut: string) {
  const formData = objectToFormData({ shortcut });
  return exec<ResponseObject>(server.post("/prototype/resetunreadpublishnotesmentions", formData));
}

export function getFirstPage(shortcut: string) {
  return exec<ExtendedProjectPage>(server.get(`/prototype/getfirstpage/${shortcut}`));
}
