
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { SsoResponse } from "@shared/models";

import { Search } from "@/common/search/Search";
import { ManageUsersModel } from "@/services/models/account";

import AxButton from "@/components/AxButton.vue";
import AxDropdownButton from "@/components/AxDropdownButton.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxInput from "@/components/AxInput.vue";
import AxList from "@/components/AxList.vue";
import AxListItem from "@/components/AxListItem.vue";
import { arrayProp } from "@/components/utils";

interface SelectOption<T> {
  value: T;
  [index: string]: any;
}

const itemsProp: PropOptions<ManageUsersModel> = {
  type: Object,
  required: true,
};

export default Vue.extend({
  components: {
    AxButton,
    AxList,
    AxListItem,
    AxDropdownButton,
    AxInput,
    AxFormGroup,
  },

  props: {
    items: itemsProp,

    activatorRef: {
      type: Object,
      required: false,
      default: undefined,
    },

    value: arrayProp<string>({
      default: () => [],
    }),

    placeholder: {
      type: String,
      default: "Enter Email addresses",
    },

    label: {
      type: String,
      default: "Enter Email addresses",
    },

    disableLabel: {
      type: Boolean,
      default: false,
    },

    inputClass: {
      type: String,
      default: "",
    },

    inputAttrs: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    inputEvents: {
      type: Object,
      required: false,
      default: () => ({}),
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    menuOpened: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const searchFields = new Map<keyof SsoResponse,(item: SsoResponse) => boolean>([
      ["userEmail", () => true],
      ["nickname", () => true],
    ]);
    const searchEngine: Search<SsoResponse> = new Search<SsoResponse>([], searchFields);

    return {
      isOpen: false,
      searchQuery: "",
      searchEngine,
      searchFields,
      pointer: 0,
      optionHeight: 30,
    };
  },

  computed: {
    filteredOptions(): Array<SelectOption<SsoResponse>> {
      if (!this.searchQuery) {
        return this.users.map(user => ({ value: user }));
      }
      if (!this.searchEngine.items.length) this.initSearch();
      return this.searchEngine
        .doSearch(this.searchQuery)
        .map(result => ({
          value: result.item,
        }))
        .filter(item => !this.isInvited(item.value));
    },

    users(): SsoResponse[] {
      return this.items.users.map(item => item.user);
    },

    contentClasses(): any {
      return this.isOpen && this.filteredOptions.length > 0 ? "content-list" : "border-0";
    },

    pointerPosition(): number {
      return this.pointer * this.optionHeight;
    },
  },

  watch: {
    filteredOptions() {
      this.pointerAdjust();
    },

    searchQuery(searchQuery) {
      if (searchQuery.length > 0) {
        this.activate();
      } else {
        this.deactivate(false);
      }
    },

    value(value) {
      this.$emit("update:input-started", value.length > 0);
    },

    isOpen(value) {
      this.$emit("update:menu-opened", value);
    },

    items() {
      this.initSearch();
    },
  },

  methods: {
    initSearch() {
      this.searchEngine = new Search<SsoResponse>(this.users, this.searchFields);
    },

    isInvited(user: SsoResponse): boolean {
      const { items } = this;
      return !!(
        items.usersInvite
        && items.usersInvite.userEmails
        && items.usersInvite.userEmails.indexOf(user.userEmail) > -1
      );
    },

    toggleCallback(_openedMenu: boolean) {
      _openedMenu ? this.activate() : this.deactivate();
    },

    pointerForward() {
      if (this.isOpen && this.pointer < this.filteredOptions.length - 1) {
        this.pointer++;

        const user = this.filteredOptions[this.pointer].value;
        if (this.isInvited(user)) this.pointerForward();

        const listElement = (this.$refs.list as any).$el as HTMLElement;
        if (listElement.scrollTop <= this.pointerPosition) {
          listElement.scrollTop = this.pointerPosition;
        }
      }
    },

    pointerBackward() {
      if (this.isOpen && this.pointer > 0) {
        this.pointer--;

        const user = this.filteredOptions[this.pointer].value;
        if (this.isInvited(user)) this.pointerBackward();

        const listElement = (this.$refs.list as any).$el as HTMLElement;
        if (listElement.scrollTop >= this.pointerPosition) {
          listElement.scrollTop = this.pointerPosition;
        }
      }
    },

    optionHighlight(index: number, user: SsoResponse): any {
      const { pointer, isInvited } = this;
      return {
        highlight: index === pointer && !isInvited(user),
      };
    },

    pointerAdjust() {
      if (this.isOpen && this.pointer >= this.filteredOptions.length - 1) {
        this.pointer = this.filteredOptions.length ? this.filteredOptions.length - 1 : 0;
      }
    },

    pointerSet(index: number) {
      this.pointer = index;
    },

    activate() {
      if (this.isOpen) return;
      if (this.searchQuery.length > 0) this.isOpen = true;
      this.focus();
    },

    deactivate(blur: boolean = true) {
      if (!this.isOpen) return;
      this.isOpen = false;
      this.searchQuery = "";
      this.pointerReset();

      if (blur) {
        const inputElement = (this.$refs.search as any).$refs.input as HTMLElement;
        inputElement.blur();
      }
    },

    focus() {
      const inputElement = (this.$refs.search as any).$refs.input as HTMLElement;
      if (inputElement && typeof inputElement.focus === "function") {
        inputElement.focus();
      }
    },

    addPointerElement(event: any) {
      if (this.searchQuery.length) {
        event.preventDefault();
        const { isOpen, isInvited, filteredOptions } = this;

        if (isOpen && filteredOptions.length > 0) {
          const user = filteredOptions[this.pointer].value;
          if (!isInvited(user)) this.select(user);
        }

        this.deactivate(false);
      }
    },

    pointerReset() {
      this.pointer = 0;
      if (this.$refs.list) {
        ((this.$refs.list as any).$el as HTMLElement).scrollTop = 0;
      }
    },

    select(user: SsoResponse) {
      this.$emit("select", user, this.deactivate);
      this.$emit("input", [...this.value, user.userEmail]);
    },

    remove() {
      if (this.searchQuery.length === 0 && this.value.length > 0) {
        const lastSelected = this.value[this.value.length - 1];
        this.$emit("input", this.value.filter(val => val !== lastSelected));
        this.searchQuery = lastSelected;
      }
    },
  },
});
