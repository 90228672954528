import { FilesystemJs, SsoResponse } from "@/generated/models";

export enum MigrationSteps {
  Idle,
  GetStarted,
  SelectSubInstance,
  PickWorkspaces,
  PickUsers,
  Confirmation,
  TransferInProgress,
  Completed,
  AuthExpired,
}

export interface AxShareMigration {
  inProgress: boolean;
  migrationId: string;
  sessionToken: string;
  domainPrefix: string;
  step: MigrationSteps;
  failedStep?: MigrationSteps;
  workspacesToMigrate: FilesystemJs[];
  usersToMigrate: SsoResponse[];
  userActivationRequired: boolean;
  superAdmin?: SsoResponse;
}

const state = (): AxShareMigration => ({
  inProgress: false,
  migrationId: "",
  domainPrefix: "",
  sessionToken: "",
  step: MigrationSteps.Idle,
  workspacesToMigrate: [],
  usersToMigrate: [],
  userActivationRequired: false,
  superAdmin: undefined,
});

export default state;
