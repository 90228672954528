
import Vue, { VNode } from "vue";

import AxTreeNode, { AxTreeNodeProps } from "./AxTreeNode.vue";

import { TreeNode, TreeNodeWithState } from "@/components/types/AxTree";
import { arrayProp } from "@/components/utils";

export type AxTreeNodeInstance = InstanceType<typeof AxTreeNode>;
const props = {
  value: arrayProp<string>(),
  items: arrayProp<TreeNode>(),
  open: arrayProp<string>(),
  active: arrayProp<string>(),
  activeItems: arrayProp<TreeNode>(),
  selectedItems: arrayProp<TreeNode>(),
  openItems: arrayProp<TreeNode>(),
};

export default Vue.extend({
  components: {
    AxTreeNode,
  },

  provide(): any {
    return { tree: this };
  },

  props: {
    openAll: {
      type: Boolean,
      default: false,
    },
    multipleActive: {
      type: Boolean,
      default: false,
    },
    ...props,
    ...AxTreeNodeProps,
  },

  data() {
    const nodes: Record<string, TreeNodeWithState> = {};
    const selectedCache: Record<string, TreeNodeWithState> = {};
    const activeCache: Record<string, TreeNodeWithState> = {};
    const openedCache: Record<string, TreeNodeWithState> = {};
    const indeterminateItems: Record<string, TreeNodeWithState> = {};
    return {
      nodes,
      selectedCache,
      activeCache,
      openedCache,
      indeterminateItems,
    };
  },

  watch: {
    items() {
      this.initTree();
      this.refreshOpen();
    },
  },

  created() {
    this.initTree();
  },

  mounted() {
    this.refreshOpen();
  },

  methods: {
    initTree() {
      this.buildTree(this.items);
      this.value.forEach(key => this.updateSelected(key, true));
      this.emitSelected();
      this.active.forEach(key => this.updateActive(key, true));
      this.emitActive();
    },

    refreshOpen() {
      if (this.openAll) {
        Object.keys(this.nodes).forEach(key => this.updateOpen(key, true));
      } else {
        this.open.forEach(key => this.updateOpen(key, true));
      }
      this.emitOpen();
    },

    buildTree(items: TreeNode[], parent: TreeNodeWithState | TreeNode | null = null) {
      for (const item of items) {
        const key = item.id;
        const children = item.children || [];
        const oldNode = this.nodes.hasOwnProperty(key)
          ? this.nodes[key]
          : {
            isSelected: false,
            isIndeterminate: false,
            isActive: false,
            isOpen: false,
            children: [],
            data: item.data,
            id: item.id,
            name: item.name,
            parent,
          };

        const node: any = {
          parent,
          children,
          data: item.data,
          id: item.id,
          name: item.name,
          isActive: false,
          isOpen: false,
          isSelected: false,
          isIndeterminate: false,
        };

        this.buildTree(children, item);

        // This fixed bug with dynamic children resetting selected parent state
        if (!this.nodes.hasOwnProperty(key) && parent !== null && this.nodes.hasOwnProperty(parent.id)) {
          node.isSelected = this.nodes[parent.id].isSelected;
          node.isIndeterminate = this.nodes[parent.id].isIndeterminate;
        } else {
          node.isSelected = oldNode.isSelected;
          node.isIndeterminate = oldNode.isIndeterminate;
        }

        node.isActive = oldNode.isActive;
        node.isOpen = oldNode.isOpen;

        this.nodes[key] = !children.length ? node : this.calculateState(node, this.nodes);

        // Don't forget to rebuild cache
        if (this.nodes[key].isSelected) Vue.set(this.selectedCache, key, this.nodes[key]);
        if (this.nodes[key].isActive) Vue.set(this.activeCache, key, this.nodes[key]);
        if (this.nodes[key].isOpen) Vue.set(this.openedCache, key, this.nodes[key]);
        if (this.nodes[key].isIndeterminate) Vue.set(this.indeterminateItems, key, this.nodes[key]);
      }
    },

    calculateState(node: TreeNodeWithState, state: Record<string, TreeNodeWithState>) {
      const counts: number[] = node.children.reduce(
        (acc: number[], child: TreeNode) => {
          acc[0] += +Boolean(state[child.id].isSelected);
          acc[1] += +Boolean(state[child.id].isIndeterminate);
          return acc;
        },
        [0, 0],
      );

      node.isSelected = !!node.children.length && counts[0] === node.children.length;
      node.isIndeterminate = !node.isSelected && (counts[0] > 0 || counts[1] > 0);

      return node;
    },

    updateActive(key: string, isActive: boolean) {
      if (!this.nodes.hasOwnProperty(key)) return;

      if (!this.multipleActive) {
        this.activeCache = {};
      }

      const node = this.nodes[key];
      if (!node) return;

      if (isActive) Vue.set(this.activeCache, key, node);
      else Vue.delete(this.activeCache, key);
      node.isActive = isActive;
    },

    updateSelected(key: string, isSelected: boolean) {
      if (!this.nodes.hasOwnProperty(key)) return;
      const node = this.nodes[key];

      const changed: Record<string, TreeNodeWithState> = {};

      const descendants = [node, ...this.getDescendants(key)];
      descendants.forEach(descendant => {
        const id = descendant.id;
        this.nodes[id].isSelected = isSelected;
        this.nodes[id].isIndeterminate = false;
        changed[id] = this.nodes[id];
      });

      const parents = this.getParents(key);
      parents.forEach(parent => {
        const id = parent.id;
        this.nodes[id] = this.calculateState(this.nodes[id], this.nodes);
        changed[id] = this.nodes[id];
      });

      Object.entries(changed).forEach(kv => {
        const [k, v] = kv;
        v.isSelected ? Vue.set(this.selectedCache, k, v) : Vue.delete(this.selectedCache, k);
        v.isIndeterminate ? Vue.set(this.indeterminateItems, k, v) : Vue.delete(this.indeterminateItems, k);
      });
    },

    updateOpen(key: string, isOpen: boolean) {
      if (!this.nodes.hasOwnProperty(key)) return;

      const node = this.nodes[key];
      node.isOpen = isOpen;
      node.isOpen ? Vue.set(this.openedCache, key, node) : Vue.delete(this.openedCache, key);
    },

    getDescendants(key: string, descendants: TreeNodeWithState[] = []) {
      const children = this.nodes[key].children;
      descendants.push(...children);
      for (const child of children) {
        descendants = this.getDescendants(child.id, descendants);
      }

      return descendants;
    },

    getParents(key: string) {
      let parent = this.nodes[key].parent;

      const parents = [];
      while (parent !== null) {
        parents.push(parent);
        parent = this.nodes[parent.id].parent;
      }

      return parents;
    },

    isOpen(item: TreeNodeWithState) {
      return this.openedCache.hasOwnProperty(item.id);
    },

    isActive(item: TreeNodeWithState) {
      return this.activeCache.hasOwnProperty(item.id);
    },

    isSelected(item: TreeNodeWithState) {
      return this.selectedCache.hasOwnProperty(item.id);
    },

    isIndeterminate(item: TreeNodeWithState) {
      return this.indeterminateItems.hasOwnProperty(item.id);
    },

    emitOpen() {
      this.$emit("update:open", Object.keys(this.openedCache));
      this.$emit("update:openItems", Object.values(this.openedCache).map(i => i.data));
    },

    emitSelected() {
      this.$emit("input", Object.keys(this.selectedCache));
      this.$emit("update:selectedItems", Object.values(this.selectedCache).map(i => i.data));
    },

    emitActive() {
      this.$emit("update:active", Object.keys(this.activeCache));
      this.$emit("update:activeItems", Object.values(this.activeCache).map(i => i.data));
    },
  },

  render(h): VNode {
    const children = this.items.length
      ? this.items.map(item => h(AxTreeNode, {
        key: item.id,
        props: {
          item,
          activatable: this.activatable,
          selectable: this.selectable,
          openOnClick: this.openOnClick,
        },
        scopedSlots: this.$scopedSlots,
      }))
      : this.$scopedSlots.default && this.$scopedSlots.default({});

    return h("div", { staticClass: "ax-tree" }, children);
  },
});
