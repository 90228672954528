
import Vue from "vue";

import { FilesystemNodeSearch, FilesystemNodeType, FilesystemNodeTypes } from "@/common/fs";
import { SearchResult } from "@/common/search/SearchResult";
import FilesystemViewer from "@/layouts/FilesystemViewer.vue";
import { Fetch } from "@/store/fs/actionTypes";

import AxFilesystemSearchTable from "@/components/AxFilesystemSearchTable.vue";
import AxFilesystemViewerActionsBase from "@/components/AxFilesystemViewerActionsBase.vue";
import AxFilesystemViewerHeader from "@/components/AxFilesystemViewerHeader.vue";
import AxIcon from "@/components/AxIcon.vue";
import { FilesystemSearchItem } from "@/components/types/AxSearch";
import { arrayProp } from "@/components/utils";

export default Vue.extend({
  components: {
    FilesystemViewer,
    AxFilesystemSearchTable,
    AxFilesystemViewerHeader,
    AxFilesystemViewerActionsBase,
    AxIcon,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    query: {
      type: String,
      default: "",
    },

    results: arrayProp<SearchResult<FilesystemSearchItem>>({
      default: () => [],
    }),
  },

  data() {
    const searchNode: FilesystemNodeSearch = {
      id: "search",
      name: "Search Results",
      type: FilesystemNodeType.Search,
      listingNode: undefined,
      parent: undefined,
      siblings: [],
      totalItems: 0,
      created: new Date(),
    };

    return {
      searchNode,
      modified: false,
      node: undefined as FilesystemNodeTypes | undefined,
    };
  },

  computed: {
    navigating(): boolean {
      return this.$store.getters.navigating;
    },
  },

  activated() {
    this.reInitData();
  },

  created() {
    this.reInitData();
  },

  async deactivated() {
    await this.refreshNavigate();
  },

  async beforeDestroy() {
    await this.refreshNavigate();
  },

  methods: {
    onModified() {
      this.modified = true;
    },

    async refreshNavigate() {
      if (this.modified && this.node) {
        await this.$store.dispatch(new Fetch(this.node));
      }
    },

    reInitData() {
      this.modified = false;
      this.node = this.$store.getters.current.node;
    },
  },
});
