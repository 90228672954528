import { RouteConfig } from "vue-router";

import { wellKnownRoutes } from "@/common/axshare/routing";
import Home from "@/pages/Home.vue";
import NotFound from "@/pages/NotFound.vue";
import ProjectNotShared from "@/pages/ProjectNotShared.vue";
import SomethingNotQuiteRight from "@/pages/SomethingNotQuiteRight.vue";
import WorkspaceAccessRequested from "@/pages/WorkspaceAccessRequested.vue";
import desktopPluginsRouter, { Keys as desktopPlugins } from "@/router/desktop-plugins";
import fsRouter from "@/router/fs";
import loginRouter, { Keys as login } from "@/router/login";
import projectRouter from "@/router/project";
import ssoRouter, { Keys as sso } from "@/router/sso";
import subinstanceRouter, { Keys as subinstance } from "@/router/subinstance";

export type Keys = "home"
  | "notFound"
  | "projectNotShared"
  | "workspaceAccessRequested"
  | "somethingNotQuiteRight"
  | login
  | subinstance
  | desktopPlugins
  | sso;

const routes: { readonly [R in Keys]: RouteConfig } = {
  home: {
    ...wellKnownRoutes.home,
    component: Home,
    meta: {
      safeToApplySWUpdate: true,
    },
  },
  ...loginRouter.routes,
  ...subinstanceRouter.routes,
  ...desktopPluginsRouter.routes,
  ...ssoRouter.routes,
  projectNotShared: {
    path: "/project-not-shared/:workspaceId/:shortcut",
    name: "projectNotShared",
    component: ProjectNotShared,
    props: true,
    beforeEnter(to, _from, next) {
      const { redirect, retry } = to.query;
      if (retry && typeof redirect === "string") {
        window.location.href = redirect;
        return;
      }
      return next();
    },
  },
  workspaceAccessRequested: {
    path: "/workspace-access-requested/:workspaceName",
    name: "workspaceAccessRequested",
    component: WorkspaceAccessRequested,
    props: true,
  },
  somethingNotQuiteRight: {
    path: "/something-not-quite-right",
    name: "somethingNotQuiteRight",
    component: SomethingNotQuiteRight,
  },
  notFound: {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      requiresAuth: false,
    },
  },
};

const config: RouteConfig[] = [
  {
    ...routes.home,
    redirect: fsRouter.routes.fs,
    children: [...fsRouter.config, ...projectRouter.config, ...subinstanceRouter.config],
  },
  ...loginRouter.config,
  ...desktopPluginsRouter.config,
  ...ssoRouter.config,
  routes.projectNotShared,
  routes.workspaceAccessRequested,
  routes.somethingNotQuiteRight,
  routes.notFound,
];

export default {
  routes,
  config,
};
