import Vue from "vue";
import { PropOptions } from "vue/types/options";

import { SharedFilesystemMappingJs } from "@shared/models";

import { FilesystemNodeWorkspace } from "@/common/fs";
import { AxShare } from "@/store/state";

const workspaceProp: PropOptions<FilesystemNodeWorkspace> = {
  type: Object,
  required: true,
};

export const workspaceMembersMixin = Vue.extend({
  props: {
    workspace: workspaceProp,
  },

  computed: {
    isSubInstance(): boolean {
      const { axShareConfig } = this.$store.state as AxShare;
      return axShareConfig !== null && axShareConfig.IsSubInstance;
    },

    sharedWith(): SharedFilesystemMappingJs[] {
      // ordering: owner -> current user -> everyone else

      let users = this.workspace ? this.workspace.users : [];
      const sharedWith: SharedFilesystemMappingJs[] = [];

      const { yourself, iAmTheOwner, didJoinWorkspace } = this;

      const owner = this.owner;
      if (owner) {
        sharedWith.push(owner);
        users = users.filter(u => u.user.userId !== owner.user.userId);
      }

      if (yourself && !iAmTheOwner && didJoinWorkspace) {
        sharedWith.push(yourself);
        users = users.filter(u => u.user.userId !== yourself.user.userId);
      }

      sharedWith.push(...users);

      return sharedWith;
    },

    yourself(): SharedFilesystemMappingJs | undefined {
      const { user } = this.$store.state as AxShare;
      if (user.userInfo) {
        const profileImageUrl = user.userInfo.profileImageUrl || (user.profile ? user.profile.Img : "");
        const yourself: SharedFilesystemMappingJs = {
          user: {
            ...user.userInfo,
            profileImageUrl,
          },
          isViewer: this.workspace ? this.workspace.viewOnly : false,
        };
        return yourself;
      }
      return undefined;
    },

    owner(): SharedFilesystemMappingJs | undefined {
      if (!this.workspace) return undefined;
      if (this.workspace.isOwner) return this.yourself;
      if (this.workspace.owner) {
        return {
          user: this.workspace.owner,
          isViewer: false,
        };
      }
      return undefined;
    },

    didJoinWorkspace(): boolean {
      if (!this.workspace) return false;
      if (this.workspace.isOwner) return true;
      const me = this.yourself;
      if (me && this.workspace.users.some(d => d.user.userId === me.user.userId)) {
        return true;
      }
      return false;
    },

    iAmTheOwner(): boolean {
      if (this.yourself && this.owner) {
        return this.yourself.user === this.owner.user;
      }
      return false;
    },
  },
});
