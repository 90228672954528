import { RouteConfig } from "vue-router";

import store from "@/store";

const Configure = () => import(/* webpackChunkName: "project-configure" */ "@/pages/ProjectConfigure.vue");
const Plugins = () => import(/* webpackChunkName: "project-configure-plugins" */ "@/pages/ProjectConfigurePlugins.vue");
const PluginsAdd = () => import(/* webpackChunkName: "project-configure-plugins" */ "@/pages/ProjectConfigurePluginsAdd.vue");
const PluginsEdit = () => import(/* webpackChunkName: "project-configure-plugins" */ "@/pages/ProjectConfigurePluginsEdit.vue");
const PluginsPages = () => import(/* webpackChunkName: "project-configure-plugins" */ "@/pages/ProjectConfigurePluginsPages.vue");
const PrettyUrls = () => import(/* webpackChunkName: "project-configure-pretty-urls" */ "@/pages/ProjectConfigurePrettyUrls.vue");
const PrettyUrlsAssign = () => import(/* webpackChunkName: "project-configure-pretty-urls" */ "@/pages/ProjectConfigurePrettyUrlsAssign.vue");
const PrettyUrlsEdit = () => import(/* webpackChunkName: "project-configure-pretty-urls" */ "@/pages/ProjectConfigurePrettyUrlsEdit.vue");
const Redirects = () => import(/* webpackChunkName: "project-configure-redirects" */ "@/pages/ProjectConfigureRedirects.vue");
const RedirectsAdd = () => import(/* webpackChunkName: "project-configure-redirects" */ "@/pages/ProjectConfigureRedirectsAdd.vue");
const RedirectsEdit = () => import(/* webpackChunkName: "project-configure-redirects" */ "@/pages/ProjectConfigureRedirectsEdit.vue");

// prettier-ignore
export type Keys =
  | "project.configure"
  | "project.configure.plugins"
  | "project.configure.plugins.add"
  | "project.configure.plugins.edit"
  | "project.configure.plugins.pages"
  | "project.configure.seo"
  | "project.configure.seo.set"
  | "project.configure.seo.edit"
  | "project.configure.redirects"
  | "project.configure.redirects.add"
  | "project.configure.redirects.edit";

const routes: { readonly [R in Keys]: RouteConfig } = {
  "project.configure": {
    path: "configure",
    name: "project.configure",
    component: Configure,
    props: true,
  },
  "project.configure.plugins": {
    path: "plugins",
    name: "project.configure.plugins",
    meta: {
      matchNameAlias: "project.configure",
    },
    component: Plugins,
    props: true,
  },
  "project.configure.plugins.add": {
    path: "add",
    name: "project.configure.plugins.add",
    component: PluginsAdd,
    props: true,
  },
  "project.configure.plugins.edit": {
    path: ":pluginId/edit",
    name: "project.configure.plugins.edit",
    component: PluginsEdit,
    props: true,
  },
  "project.configure.plugins.pages": {
    path: ":pluginId/pages",
    name: "project.configure.plugins.pages",
    component: PluginsPages,
    props: true,
  },
  "project.configure.seo": {
    path: "seo",
    name: "project.configure.seo",
    meta: {
      matchNameAlias: "project.configure",
    },
    component: PrettyUrls,
    props: true,
  },
  "project.configure.seo.set": {
    path: "set/:mode",
    name: "project.configure.seo.set",
    component: PrettyUrlsAssign,
    props: true,
  },
  "project.configure.seo.edit": {
    path: ":seoId/edit",
    name: "project.configure.seo.edit",
    component: PrettyUrlsEdit,
    props: true,
  },
  "project.configure.redirects": {
    path: "redirects",
    name: "project.configure.redirects",
    meta: {
      matchNameAlias: "project.configure",
    },
    component: Redirects,
    props: true,
  },
  "project.configure.redirects.add": {
    path: "add",
    name: "project.configure.redirects.add",
    component: RedirectsAdd,
    props: true,
  },
  "project.configure.redirects.edit": {
    path: ":redirectId/edit",
    name: "project.configure.redirects.edit",
    component: RedirectsEdit,
    props: true,
  },
};

const config = [
  {
    ...routes["project.configure"],
    redirect: () => {
      const { axShareConfig } = store.state;
      if (axShareConfig?.EnablePlugins) {
        return routes["project.configure.plugins"];
      }
      return routes["project.configure.seo"];
    },
    children: [
      {
        ...routes["project.configure.plugins"],
        children: [
          routes["project.configure.plugins.add"],
          routes["project.configure.plugins.edit"],
          routes["project.configure.plugins.pages"],
        ],
      },
      {
        ...routes["project.configure.seo"],
        children: [routes["project.configure.seo.set"], routes["project.configure.seo.edit"]],
      },
      {
        ...routes["project.configure.redirects"],
        children: [routes["project.configure.redirects.add"], routes["project.configure.redirects.edit"]],
      },
    ],
  },
];

export default {
  routes,
  config,
};
