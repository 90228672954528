import Vue from "vue";

import { ListingJs } from "@shared/models";

import {
  FilesystemNodeType,
  FilesystemNodeTypes,
  FilesystemNodeWorkspace,
  toFolderNode,
  toShortcutNode,
  toWorkspaceNode,
} from "@/common/fs";
import { removeFirst } from "@/common/lib";
import { MutationPayloadMap, MutationTypes } from "@/store/fs/mutationTypes";
import { AxShareFilesystem } from "@/store/fs/state";
import { MutationTree } from "@/store/typed";

const mutations: MutationTree<AxShareFilesystem, MutationPayloadMap> = {
  [MutationTypes.Fetching](state, { id, inProgress }) {
    Vue.set(state.fetching, id, inProgress);
  },

  [MutationTypes.Navigating](state, { id, status: { inProgress, error } }) {
    Vue.set(state.navigatingError, id, error);
    Vue.set(state.navigating, id, inProgress);
  },

  [MutationTypes.Clear](state, { id }) {
    Vue.set(state.contents, id, []);
  },

  [MutationTypes.SetContents](state, { node, contents }) {
    const nodes = nodesFromContents(node, contents);
    rememberNodes(state, [node, ...nodes]);
    Vue.set(state.contents, node.id, nodes.map(n => n.id));
    state.firstLoad = false;
  },

  [MutationTypes.NodeRemember](state, { node }) {
    rememberNode(state, node);
  },

  [MutationTypes.NodeRemove](state, { id }) {
    Vue.delete(state.nodes, id);
  },

  [MutationTypes.NodeRemoveContents](state, { parentFolder, id }) {
    const contents = state.contents[parentFolder];
    if (contents) {
      removeFirst(contents, content => content === id);
    }
  },

  [MutationTypes.SetParentNode](state, { id, parent }) {
    const node = state.nodes[id];
    if (node) {
      node.parent = parent;
    }
  },

  [MutationTypes.SetListingNode](state, { id, listingNode }) {
    const node = state.nodes[id];
    if (node && node.type === FilesystemNodeType.Workspace) {
      node.listingNode = listingNode;
    }
  },

  [MutationTypes.ProjectRename](state, { id, name }) {
    const node = state.nodes[id];
    if (node && node.type === FilesystemNodeType.Shortcut) {
      node.name = name;
      if (node.listingNode) {
        node.listingNode.Name = name;
      }
    }
    const searchItem = state.searchData.results.find(r => r.item.id === id);
    if (searchItem) {
      searchItem.item.name = name;
    }
  },

  [MutationTypes.ProjectToggleFeedback](state, { id, enabled }) {
    const node = state.nodes[id];
    if (node && node.type === FilesystemNodeType.Shortcut) {
      node.feedbackEnabled = enabled;
      if (node.listingNode) {
        node.listingNode.FeedbackEnabled = enabled;
      }
    }
  },

  [MutationTypes.ProjectToggleUserOnly](state, { id, isUserOnly }) {
    const node = state.nodes[id];
    if (node && node.type === FilesystemNodeType.Shortcut) {
      node.isUserOnly = isUserOnly;
      if (node.listingNode) {
        node.listingNode.IsUserOnly = isUserOnly;
      }
    }
  },

  [MutationTypes.WorkspaceRename](state, { id, name }) {
    const node = state.nodes[id];
    if (node && node.type === FilesystemNodeType.Workspace) {
      node.name = name;
      if (node.listingNode) {
        node.listingNode.DisplayName = name;
      }
    }
  },

  [MutationTypes.WorkspaceArchive](state, { id, archive }) {
    const node = state.nodes[id];
    if (node && node.type === FilesystemNodeType.Workspace) {
      node.isArchived = archive;
    }
  },

  [MutationTypes.FavoriteWorkspace](state, { id, favorite }) {
    const node = state.nodes[id];
    if (node && node.type === FilesystemNodeType.Workspace) {
      node.isFavorite = favorite;
    }
  },

  [MutationTypes.FolderAdd](state, { node, folderRowJs }) {
    const folderNode = toFolderNode(folderRowJs, node);
    rememberNode(state, folderNode);
    const contents = state.contents[node.id];
    contents.push(folderNode.id);
    Vue.set(state.contents, node.id, contents);
  },

  [MutationTypes.FolderRename](state, { id, name }) {
    const node = state.nodes[id];
    if (node && node.type === FilesystemNodeType.Folder) {
      node.name = name;
      if (node.listingNode) {
        node.listingNode.FolderName = name;
      }
    }
  },

  [MutationTypes.SetViewOnly](state, payload) {
    const node = state.nodes[payload.node.id];
    if (node && node.type === FilesystemNodeType.Folder) {
      node.viewOnly = payload.viewOnly;
    }
  },

  [MutationTypes.SetSearchData](state, { data }) {
    if (data.isLoading !== undefined) {
      state.searchData.isLoading = data.isLoading;
    }
    if (data.query !== undefined) {
      state.searchData.query = data.query;
    }
    if (data.results !== undefined) {
      state.searchData.results = data.results;
    }
  },

  [MutationTypes.RecentsLoad](state, { inProgress }) {
    state.recentsLoad = inProgress;
  },

  [MutationTypes.SetProjectNotificationState](state, { id, notificationState }) {
    const node = state.nodes[id];
    if (node && node.type === FilesystemNodeType.Shortcut) {
      const mergedState = { ...node.notificationState, ...notificationState };
      node.notificationState = mergedState;
      if (node.listingNode) {
        node.listingNode.NotificationState = mergedState;
      }
    }
  },

  [MutationTypes.SetFirstPage](state, { nodeId, page }) {
    state.firstPagesMap[nodeId] = page;
  },
};

export default mutations;

function rememberNodes(state: AxShareFilesystem, nodes: FilesystemNodeTypes[]) {
  for (const node of nodes) {
    rememberNode(state, node);
  }
}

function rememberNode(state: AxShareFilesystem, node: FilesystemNodeTypes) {
  if (node.type === FilesystemNodeType.Workspace) {
    preserveWorkspaceRootFolder(node, state);

    // because admins see all workspaces don't let it to override existing workspace invitation
    const existing = state.nodes[node.id];
    if (existing && existing.type === FilesystemNodeType.Invitation) {
      return;
    }
  }
  Vue.set(state.nodes, node.id, node);
}

function preserveWorkspaceRootFolder(node: FilesystemNodeWorkspace, state: AxShareFilesystem) {
  if (!node.rootFolderId) {
    const savedNode = state.nodes[node.id];
    if (savedNode && savedNode.type === FilesystemNodeType.Workspace) {
      // eslint-disable-next-line no-param-reassign
      node.rootFolderId = savedNode.rootFolderId;
    }
  }
}

function nodesFromContents(parent: FilesystemNodeTypes, contents: ListingJs): FilesystemNodeTypes[] {
  const nodes = [
    ...contents.Filesystems.map(fs => toWorkspaceNode(fs, parent)),
    ...contents.Folders.map(folder => toFolderNode(folder, parent)),
    ...contents.Shortcuts.map(shortcut => toShortcutNode(shortcut, parent)),
  ];

  // fill siblings
  for (const node of nodes) {
    node.siblings = nodes.filter(sibling => sibling.id !== node.id);
  }

  return nodes;
}
