
import { ValidationResult, PropertyValidator, ValidationContext } from "lakmus";
import Vue from "vue";

import { KeyCodeKeys } from "../common/lib";

import { arrayProp } from "./utils";

import AxButton from "@/components/AxButton.vue";
import AxChip from "@/components/AxChip.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxInput from "@/components/AxInput.vue";
import AxTags from "@/components/AxTags.vue";

const emailRegex = /^[^\s@]+@[^\s@]+$/;

export class EmailValidator extends PropertyValidator {
  constructor(errorMessage?: string) {
    super(errorMessage);
  }

  public isValid(context: ValidationContext): boolean {
    const email = context.propertyValue as string;

    if (typeof email !== "string") {
      return false;
    }

    return emailRegex.test(email);
  }

  public getErrorMessage(ctx: ValidationContext) {
    return `'${ctx.propertyValue}' is not a valid email address`;
  }
}

export default Vue.extend({
  components: {
    AxButton,
    AxChip,
    AxIcon,
    AxInput,
    AxTags,
  },

  props: {
    value: arrayProp<string>({
      default: () => [],
    }),

    autofocus: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: "name@example.com",
    },

    inputStarted: {
      type: Boolean,
      default: false,
    },

    disableAddOnBlur: {
      type: Boolean,
      default: false,
    },

    extraValidators: arrayProp<PropertyValidator>({
      default: undefined,
    }),
  },

  data() {
    const submitKeys: KeyCodeKeys[] = ["tab", "enter", "space", "comma"];
    const validators = [new EmailValidator()];
    if (this.extraValidators) {
      validators.push(...this.extraValidators);
    }

    return {
      selected: undefined as string | undefined,
      submitKeys,
      separators: ["\t", "\n\r", "\n", " ", ","],
      validators,
    };
  },

  computed: {
    innerValue: {
      get(): string[] {
        return this.value;
      },
      set(value: string[]) {
        this.$emit("input", value);
      },
    },
  },

  watch: {
    value() {
      this.$emit("change");
    },
  },

  methods: {
    select(email: string) {
      this.selected = email;
      return !!this.selected;
    },

    clearSelection() {
      this.selected = undefined;
      return false;
    },

    selectPrevious(email: string) {
      const emailIndex = this.innerValue.findIndex(inner => inner === email);
      const previous = this.innerValue[emailIndex - 1];
      return this.select(previous);
    },

    selectNext(email: string) {
      const emailIndex = this.innerValue.findIndex(inner => inner === email);
      const next = this.innerValue[emailIndex + 1];
      if (!next) {
        this.focusInput();
      } else {
        return this.select(next);
      }
    },

    selectLast() {
      const last = this.innerValue[this.innerValue.length - 1];
      return this.select(last);
    },

    focusInput() {
      // no next email in the list. return focus back to input
      const input = this.$refs.input as Vue & { focus?(): void };
      if (input && typeof input.focus === "function") {
        input.focus();
      }
      this.$emit("focus-input");
    },

    onValidationError(validationResult: ValidationResult) {
      this.$emit("validation-error", validationResult);
    },

    removeViaKeyboard(email: string, removeTag: (email: string) => void) {
      const index = this.innerValue.findIndex(val => val === email);
      const previous = this.innerValue[index - 1];
      removeTag(email);
      this.$nextTick(() => this.select(previous) || this.focusInput());
      this.onRemove(email);
    },

    onAdd() {
      this.$emit("update:input-started", true);
    },

    onRemove(email: string) {
      const emails = this.innerValue.filter(value => value !== email);
      if (emails.length === 0) {
        this.$emit("update:input-started", false);
      }
      this.$emit("remove", email);
    },

    onInputStarted(value: string) {
      this.$emit("update:input-started", !!value || this.innerValue.length > 0);
    },

    onBeforePaste() {
      this.$emit("before-paste");
    },

    onAfterPaste() {
      this.$emit("after-paste");
    },
  },
});
