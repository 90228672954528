
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { FilesystemNodeMovable, FilesystemNodeType } from "@/common/fs";
import { delay } from "@/common/lib";
import { moveItems } from "@/services/fs.service";
import {
  Fetch, NavigationRestore, NodeRemove, WorkspacesRefresh,
} from "@/store/fs/actionTypes";

import AxButton from "@/components/AxButton.vue";
import AxFilesystemTreeProjects from "@/components/AxFilesystemTreeProjects.vue";
import AxForm from "@/components/AxForm.vue";
import AxFormGroup from "@/components/AxFormGroup.vue";
import AxWorkspacesToggleAll from "@/components/AxWorkspacesToggleAll.vue";
import { Submit } from "@/components/types/AxForm";

export default Vue.extend({
  components: {
    AxForm,
    AxFormGroup,
    AxButton,
    AxFilesystemTreeProjects,
    AxWorkspacesToggleAll,
  },

  props: {
    folder: {
      type: String,
      required: true,
    },

    /* eslint-disable vue/require-default-prop */
    items: {
      type: Array,
      required: true,
    } as PropValidator<FilesystemNodeMovable[]>,
    /* eslint-enable vue/require-default-prop */
  },

  data() {
    return {
      // selected: this.folder,
      selectedObj: null as any,
      showAll: false,
    };
  },

  methods: {
    async submit({ values }: Submit<any>) {
      await moveItems(this.items, this.folder, values.selectedObj.id);
      await this.$store.dispatch(new NodeRemove(this.items, this.folder));
      this.$emit("submit");
      delay(500).then(async () => {
        const tasks = [];
        for (const item of this.items) {
          const type = item.type === FilesystemNodeType.Shortcut ? "shortcut" : "folder";
          tasks.push(this.$store.dispatch(new NavigationRestore(item.id, type, true)));
        }
        await Promise.all(tasks);
        await this.$store.dispatch(new WorkspacesRefresh());
        const { node } = this.$store.getters.current;
        await this.$store.dispatch(new Fetch(node));
      });
    },
  },
});
