
import Vue from "vue";

const recaptchaScriptId = "axRecaptchaScript";
function appendRecaptchaScript() {
  const oldScript = document.getElementById(recaptchaScriptId);
  if (!oldScript) {
    const script = document.createElement("script");
    script.id = recaptchaScriptId;
    script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
  } else {
    document.head.removeChild(oldScript);
    appendRecaptchaScript();
  }
}

function removeRecaptchaScripts() {
  (window as any).grecaptcha = undefined;
  (window as any).recaptcha = undefined;
  // remove the recaptcha iframe wrapper that remains in the body
  document.querySelectorAll("iframe[src*=recaptcha]").forEach(a => a.parentElement?.parentElement?.remove());
  document.querySelectorAll("script[src*=recaptcha]").forEach(a => a.remove());
}

export default Vue.extend({
  props: {
    sitekey: {
      type: String,
      required: true,
    },
    showBadge: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      captchaLoaded: false,
      gAssignedId: undefined as string | undefined,
    };
  },

  computed: {
    grecaptcha(): any {
      if ((window as any).grecaptcha) {
        return (window as any).grecaptcha;
      }
      return undefined;
    },
  },

  beforeMount() {
    appendRecaptchaScript();
  },

  beforeDestroy() {
    removeRecaptchaScripts();
    this.$emit("captcha-ready", false);
  },

  mounted() {
    // Initialize the recaptcha
    this.init();
  },

  methods: {
    async execute() {
      if (this.grecaptcha) {
        this.$emit("captcha-ready", false);
        await this.grecaptcha.execute(this.gAssignedId);
      }
    },
    async reset() {
      if (this.grecaptcha) {
        this.$emit("captcha-ready", false);
        await this.grecaptcha.reset(this.gAssignedId);
        this.$emit("captcha-ready", true);
      }
    },
    async callback(recaptchaToken: string) {
      if (this.grecaptcha) {
        await this.reset();
        this.$emit("captcha-callback", recaptchaToken);
        this.$emit("captcha-ready", true);
      }
    },
    render() {
      if (this.grecaptcha) {
        this.gAssignedId = this.grecaptcha.render("gRecaptcha", {
          sitekey: this.sitekey,
          size: "invisible",
          callback: this.callback,
          "expired-callback": () => {
            this.reset();
          },
        });
        this.grecaptcha.reset();
      }
    },
    init() {
      this.$emit("captcha-ready", false);
      const checkInterval = setInterval(() => {
        if (this.grecaptcha && Object.prototype.hasOwnProperty.call(this.grecaptcha, "render")) {
          clearInterval(checkInterval);
          this.render();
          this.$emit("captcha-ready", true);
          this.captchaLoaded = true;
        }
      }, 500);
    },
  },
});
