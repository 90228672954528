/* eslint-disable no-console */

import { register } from "register-service-worker";
import Vue from "vue";
import VueRouter, { NavigationGuard } from "vue-router";

import { isProduction, isElectron, serviceWorkerEnabled } from "@/common/environment";
import { hasServiceWorker, retryForever } from "@/common/lib";
import { SWEvents } from "@/service-worker/SWEvents";
import { SWUpdateEvent } from "@/service-worker/SWUpdateEvent";

interface Options {
  app: Vue;
  router: VueRouter;
}

let updateEvent: SWUpdateEvent | undefined;

export default ({ app, router }: Options) => {
  if (!hasServiceWorker) return;
  if (!isProduction) return;

  if (!serviceWorkerEnabled) {
    // clean up all existing service-worker registrations
    navigator.serviceWorker
      .getRegistrations()
      .then(registrations => {
        for (const registration of registrations) {
          registration.unregister();
        }
      })
      .catch(_err => {
        // it can fail in browser, specifically in Firefox when "Delete cookies and site data when Firefox is closed" is enabled
        // silently skip
      });

    return;
  }

  // temporarily disabling service worker registration for electron
  if (isElectron) return;

  let reloading = false;
  const applyPendingSWUpdate: NavigationGuard = (to, _from, next) => {
    if (!to.meta || !to.meta.safeToApplySWUpdate) return next();

    if (updateEvent) {
      updateEvent
        .skipWaiting()
        .then(() => {
          window.location.reload();
          reloading = true;
        })
        .catch(() => next());
      updateEvent = undefined;
    } else {
      next();
    }
  };
  router.beforeEach(applyPendingSWUpdate);

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "[sw] App is being served from cache by a service worker.\nFor more details, visit https://goo.gl/AFskqB",
      );
      app.$emit(SWEvents.Ready);
    },
    registered(registration: ServiceWorkerRegistration) {
      console.log("[sw] Service worker has been registered.");
      app.$emit(SWEvents.Registered);

      // poll for updates more often than that default service worker does
      const oneHourMs = 1000 * 60 * 60;
      retryForever(async () => {
        await registration.update();
      }, oneHourMs);
    },
    cached() {
      console.log("[sw] Content has been cached for offline use.");
      app.$emit(SWEvents.Cached);
    },
    updatefound() {
      console.log("[sw] New content is downloading.");
      app.$emit(SWEvents.UpdateFound);
    },
    updated(registration: ServiceWorkerRegistration) {
      console.log("[sw] New content is available; please refresh.");
      updateEvent = new SWUpdateEvent(registration);
      app.$emit(SWEvents.Updated, updateEvent);
    },
    offline() {
      console.log("[sw] No internet connection found. App is running in offline mode.");
      app.$emit(SWEvents.Offline);
    },
    error(error) {
      console.error("[sw] Error during service worker registration:", error);
      app.$emit(SWEvents.Error);
    },
  });

  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (reloading) return;

    const { currentRoute } = router;
    if (currentRoute && currentRoute.meta && currentRoute.meta.safeToApplySWUpdate) {
      window.location.reload();
    }
  });
};
