export class SWUpdateEvent {
  constructor(private readonly registration: ServiceWorkerRegistration) {}

  /**
   * Check if the new service worker exists or not.
   */
  public update() {
    return this.registration.update();
  }

  /**
   * Activate new service worker to work 'location.reload()' with new data.
   */
  public skipWaiting() {
    const worker = this.registration.waiting;
    if (!worker) {
      return Promise.resolve();
    }

    console.log("[sw] Doing worker.skipWaiting().");
    return new Promise((resolve, reject) => {
      const channel = new MessageChannel();

      channel.port1.onmessage = event => {
        console.log("[sw] Done worker.skipWaiting().");
        if (event.data.error) {
          reject(event.data.error);
        } else {
          resolve(event.data);
        }
      };

      worker.postMessage({ type: "skip-waiting" }, [channel.port2]);
    });
  }
}
