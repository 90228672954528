
import Vue from "vue";
import { PropValidator } from "vue/types/options";

import { FilesystemNodeClonable } from "@/common/fs";
import { cloneShortcut } from "@/services/project.service";

import AxButton from "@/components/AxButton.vue";
import AxForm from "@/components/AxForm.vue";
import { Submit } from "@/components/types/AxForm";

const item: PropValidator<FilesystemNodeClonable> = {
  type: Object,
  required: true,
};
const props = {
  item,
};

export default Vue.extend({
  components: {
    AxForm,
    AxButton,
  },

  props: {
    folder: {
      type: String,
      required: true,
    },
    ...props,
  },

  data() {
    return {
      selected: null,
    };
  },

  methods: {
    async submit(_: Submit<any>) {
      await cloneShortcut(this.item.id, this.folder);
      this.$emit("submit");
    },
  },
});
