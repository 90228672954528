
import { PositioningStrategy } from "@popperjs/core";
import Vue from "vue";
import { PropOptions } from "vue/types/options";

import AxButton from "./AxButton.vue";
import AxMenu from "./AxMenu.vue";

import { prop } from "@/components/utils";

export default Vue.extend({
  components: {
    AxButton,
    AxMenu,
  },

  props: {
    color: {
      type: String,
      default: "default",
    },

    block: {
      type: Boolean,
      default: false,
    },

    closeOnContentClick: {
      type: Boolean,
      default: true,
    },

    right: {
      type: Boolean,
      default: false,
    },

    left: {
      type: Boolean,
      default: false,
    },

    top: {
      type: Boolean,
      default: false,
    },

    bottom: {
      type: Boolean,
      default: false,
    },

    middle: {
      type: Boolean,
      default: false,
    },

    skidding: {
      type: Number,
      default: 0,
    },

    distance: {
      type: Number,
      default: -3,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    contentClass: {
      type: String,
      default: "",
    },

    overlay: {
      type: Boolean,
      default: false,
    },

    active: {
      type: Boolean,
      default: false,
    },

    toggleCallback: {
      type: Function,
      default: () => {},
    } as PropOptions<(opened: boolean) => void>,

    attach: {
      type: [String, Boolean],
      default: true,
    },

    controlManually: {
      type: Boolean,
      default: false,
    },

    untabbable: {
      type: Boolean,
      default: false,
    },

    scrollToIndex: {
      type: Number,
      required: false,
      default: undefined,
    },

    itemHeight: {
      type: Number,
      required: false,
      default: undefined,
    },

    nativeStyled: {
      type: Boolean,
      default: false,
    },

    strategy: prop<PositioningStrategy>({
      required: false,
      default: undefined,
    }),

    activatorRef: {
      type: Object,
      required: false,
      default: undefined,
    },

    useActivatorWidth: {
      type: Boolean,
      default: false,
    },

    arrow: {
      type: Boolean,
      default: false,
    },

    dropMaxHeight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dropdownActive: this.active,
    };
  },

  watch: {
    active(value) {
      this.dropdownActive = value;
    },
  },

  methods: {
    menuToggled(menuOpened: boolean) {
      this.toggleCallback(menuOpened);
    },

    onActivatorClicked(event: any) {
      // Click happens, but triggers toggle twice...
      if (this.disabled) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
  },
});
