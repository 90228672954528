
import Vue, { VNode } from "vue";
import { PropValidator } from "vue/types/options";

import AxButton from "@/components/AxButton.vue";
import AxIcon from "@/components/AxIcon.vue";
import { TreeNodeWithState } from "@/components/types/AxTree";

const itemProp: PropValidator<TreeNodeWithState> = {
  type: Object,
  required: true,
};
const props = {
  item: itemProp,
};
export const AxTreeNodeProps = {
  activatable: {
    type: Boolean,
    default: false,
  },
  selectable: {
    type: Boolean,
    default: false,
  },
  openOnClick: {
    type: Boolean,
    default: false,
  },
};

export default Vue.extend({
  name: "AxTreeNode",
  inject: {
    tree: {
      default: null,
    },
  },

  props: {
    ...props,
    ...AxTreeNodeProps,
  },

  computed: {
    key(): string {
      return this.item.id;
    },

    children(): TreeNodeWithState[] | null {
      return this.item.children;
    },

    text(): string {
      return this.item.name;
    },

    treeview(): any {
      return (this as any).tree;
    },

    isOpen(): boolean {
      return this.treeview.isOpen(this.item);
    },

    isActive(): boolean {
      return this.treeview.isActive(this.item);
    },

    isSelected(): boolean {
      return this.treeview.isSelected(this.item);
    },

    isIndeterminate(): boolean {
      return this.treeview.isIndeterminate(this.item);
    },
  },

  methods: {
    genChild(item: TreeNodeWithState): VNode {
      return this.$createElement(this.$options.name, {
        key: item.id,
        props: {
          item,
          activatable: this.activatable,
          selectable: this.selectable,
          openOnClick: this.openOnClick,
        },
        scopedSlots: this.$scopedSlots,
      });
    },

    genNode(): VNode {
      const children = [this.genContent()];

      if (this.selectable) children.unshift(this.genCheckbox());
      if (this.children && this.children.length > 0) children.unshift(this.genToggle());

      return this.$createElement(
        AxButton,
        {
          props: {
            color: "link",
            block: true,
          },
          staticClass: "ax-tree-node__root",
          attrs: {
            tabindex: this.activatable ? 0 : -1,
          },
          class: {
            "ax-tree-node--click": true,
            "ax-tree-node--activatable": this.activatable,
          },
          on: {
            click: () => {
              if (this.openOnClick && this.children) {
                this.open();
              } else if (this.activatable) {
                this.activate();
              }
            },
          },
        },
        children,
      );
    },

    genCheckbox() {
      return this.$createElement("input", {
        attrs: {
          type: "checkbox",
        },
        domProps: {
          indeterminate: this.isIndeterminate,
          checked: this.isSelected,
        },
        staticClass: "ax-tree-node__checkbox",
        on: {
          click: (e: MouseEvent) => {
            e.stopPropagation();
            this.select();
          },
        },
      });
    },

    genLabel() {
      const children = this.$scopedSlots.item ? this.$scopedSlots.item({ item: this.item }) : this.text;
      return this.$createElement(
        "label",
        {
          slot: "label",
          staticClass: "ax-tree-node__label ax-tree-node--click",
        },
        [children],
      );
    },

    genContent() {
      const children = [this.genLabel()];
      return this.$createElement("div", { staticClass: "ax-tree-node__content" }, children);
    },

    genToggle() {
      const icon = this.$createElement(AxIcon, {
        props: {
          name: "caret",
          rotate: this.isOpen ? "0" : "-90deg",
        },
        staticClass: "ax-tree-node__toggle-icon",
      });
      return this.$createElement(
        AxButton,
        {
          props: {
            color: "link",
            medium: true,
          },
          staticClass: "ax-tree-node__toggle",
          class: {
            "ax-tree-node__toggle--open": this.isOpen,
            "ax-tree-node--click": true,
          },
          on: {
            click: (e: MouseEvent) => {
              e.stopPropagation();
              this.open();
            },
          },
        },
        [icon],
      );
    },

    genChildrenWrapper(): any {
      if (!this.isOpen || !this.children || this.children.length === 0) return null;
      const children = [this.children.map(this.genChild)];
      return this.$createElement("div", { staticClass: "ax-tree-node__children" }, children);
    },

    open() {
      this.treeview.updateOpen(this.key, !this.isOpen);
      this.treeview.emitOpen();
    },

    activate() {
      this.treeview.updateActive(this.key, !this.isActive);
      this.treeview.emitActive();
    },

    select() {
      this.treeview.updateSelected(this.key, !this.isSelected);
      this.treeview.emitSelected();
    },
  },

  render(h): VNode {
    const children = [this.genNode()];
    children.push(this.genChildrenWrapper());
    return h(
      "div",
      {
        staticClass: "ax-tree-node",
        class: {
          "ax-tree-node--selected": this.isSelected,
          "ax-tree-node--active": this.isActive,
          "ax-tree-node--click": this.openOnClick,
        },
      },
      children,
    );
  },
});
