import { getSubInstanceInfo, getUserInfo } from "@/services/admin.service";
import store from "@/store";
import { ActionPayloadMap, ActionTypes, GetAdminSubInstanceInfo, GetAdminUserInfo } from "@/store/admin/actionTypes";
import { SetAdminSubInstanceInfo, SetAdminUserInfo } from "@/store/admin/mutationTypes";
import { AxShareAdmin } from "@/store/admin/state";
import { asyncAction } from "@/store/async-action";
import { AxShare } from "@/store/state";
import { ActionTree } from "@/store/typed";

const actions: ActionTree<AxShareAdmin, AxShare, ActionPayloadMap> = {
  async [ActionTypes.GetAdminInfo](context) {
    const { accountService, axShareConfig } = store.state as AxShare;

    const isAuthed = !!accountService && accountService().IsAuthed;
    // No need to request admin user info for non-auther users or non-subinstance app
    if (!isAuthed || !axShareConfig || !axShareConfig.IsSubInstance) {
      context.commit(new SetAdminUserInfo(undefined));
      return;
    }
    const getInfo = [context.dispatch(new GetAdminUserInfo()), context.dispatch(new GetAdminSubInstanceInfo())];
    await Promise.all(getInfo);
  },

  async [ActionTypes.GetAdminUserInfo](context) {
    await asyncAction(
      context,
      ActionTypes.GetAdminUserInfo,
      async () => getUserInfo(),
      response => new SetAdminUserInfo(response)
    );
  },

  async [ActionTypes.GetAdminSubInstanceInfo](context) {
    await asyncAction(
      context,
      ActionTypes.GetAdminSubInstanceInfo,
      async () => getSubInstanceInfo(),
      response => new SetAdminSubInstanceInfo(response)
    );
  },

  [ActionTypes.SetAdminUserInfo](context, { userInfo }) {
    context.commit(new SetAdminUserInfo(userInfo));
  },
};

export default actions;
