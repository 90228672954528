
import Vue from "vue";

import { copyTextToClipboard, delay } from "@/common/lib";

import AxButton from "@/components/AxButton.vue";

export default Vue.extend({
  components: {
    AxButton,
  },

  props: {
    value: {
      type: String,
      required: true,
    },

    buttonOptions: {
      type: Object,
      required: false,
      default: () => ({
        color: "secondary",
        small: true,
      }),
    },

    buttonText: {
      type: String,
      default: "Copy",
    },

    buttonTextCopied: {
      type: String,
      default: "Copied",
    },
  },

  data() {
    return {
      delay: false,
      copyButtonText: this.buttonText,
    };
  },

  methods: {
    async copy() {
      const wrapper = this.$refs.copyToClipboard as Element;
      if (copyTextToClipboard(this.value, wrapper)) {
        this.$emit("copy");
        this.copyButtonText = this.buttonTextCopied;
        this.delay = true;
        await delay(2000);
        this.delay = false;
        this.copyButtonText = this.buttonText;
      }
    },
  },
});
