
import Vue from "vue";

import * as expo from "@shared/expo";

import { AnalyticsClickEvents } from "@/common/axshare";
import { CreateExpoProjectModelValidator } from "@/common/fs";
import { ROUTES } from "@/router";
import { checkExpoNameValid, createProject, getDeviceList } from "@/services/expo.service";
import { Fetch } from "@/store/fs/actionTypes";

import { AxShare } from "../store/state";

import AxButton from "@/components/AxButton.vue";
import AxChoice from "@/components/AxChoice.vue";
import AxCreateProjectCompact from "@/components/AxCreateProjectCompact.vue";
import AxForm from "@/components/AxForm.vue";
import AxIcon from "@/components/AxIcon.vue";
import AxInput from "@/components/AxInput.vue";
import AxInputGroup from "@/components/AxInputGroup.vue";
import AxPanel from "@/components/AxPanel.vue";
import AxSelectNative from "@/components/AxSelectNative.vue";
import { Submit } from "@/components/types/AxForm";

interface DeviceOption {
  text: string;
  value: expo.DeviceType;
}

interface ExpoNewProjectModel {
  Name: string;
  Password: string;
  FolderId: string;
  currentFolderId: string;
  Platform: expo.DeviceType;
  Width?: number;
  Height?: number;
  CustomDimensions: Record<string, expo.Dimensions>;
}

export default Vue.extend({
  components: {
    AxForm,
    AxButton,
    AxPanel,
    AxInput,
    AxInputGroup,
    AxIcon,
    AxCreateProjectCompact,
    AxChoice,
    AxSelectNative,
  },

  props: {
    parentFolder: {
      type: String,
      required: true,
    },

    compact: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const orientations = [
      { value: expo.Orientation.Portrait, text: "Portrait" },
      { value: expo.Orientation.Landscape, text: "Landscape" },
    ];
    const defaultDimensions: Record<string, expo.Dimensions> = {
      [expo.DeviceType.Web]: { Width: 1024, Height: 800 },
      [expo.DeviceType.Custom]: { Width: 2000, Height: 2000 },
    };
    const model: ExpoNewProjectModel = {
      Name: "",
      Password: "",
      FolderId: this.parentFolder,
      currentFolderId: this.parentFolder,
      Platform: expo.DeviceType.iPhone8,
      CustomDimensions: defaultDimensions,
    };

    const devices = async () => (await getDeviceList()).map(deviceItem => {
      const { DeviceName, Dimensions, Device } = deviceItem;
      let deviceText = DeviceName;
      if (Dimensions) {
        deviceText = `${deviceText} (${Dimensions.Width} x ${Dimensions.Height})`;
      }
      return {
        text: deviceText,
        value: Device,
      };
    });

    return {
      AnalyticsClickEvents,
      orientations,
      defaultDimensions,
      model,
      validator: CreateExpoProjectModelValidator,
      devices,
      projectNameUserChanged: false,
      errors: [] as string[],
      loading: false,
      defaultDevice: expo.DeviceType.iPhone12Pro,
    };
  },

  methods: {
    async submit({ values }: Submit<ExpoNewProjectModel>) {
      // save reference to file in case if "Cancel" will be click while name check hasn't finished yet
      await checkExpoNameValid(values.Name, values.FolderId);

      // eslint-disable-next-line no-param-reassign
      values.Width = values.CustomDimensions[values.Platform] ? values.CustomDimensions[values.Platform].Width : 0;
      // eslint-disable-next-line no-param-reassign
      values.Height = values.CustomDimensions[values.Platform] ? values.CustomDimensions[values.Platform].Height : 0;
      const response = await createProject(values);
      if (response && response.Vars && response.Vars.shortcut) {
        const shortcut = response.Vars.shortcut.toLowerCase();
        this.loading = true;
        await this.$store.dispatch(new Fetch(this.$store.getters.getNodeByFolderId(this.parentFolder)));
        this.$router.push(
          {
            name: ROUTES["project.overview"].name,
            params: {
              shortcut,
            },
          },
          () => {
            this.loading = false;
          },
        );
      } else {
        this.$emit("submit");
      }
    },

    customDimensions(platform: expo.DeviceType) {
      if (platform) {
        return platform === expo.DeviceType.Custom || platform === expo.DeviceType.Web;
      }
      return false;
    },

    isWebPlatform(platform: expo.DeviceType) {
      if (platform) {
        return platform === expo.DeviceType.Web;
      }
      return false;
    },
  },
});
