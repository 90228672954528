
import Vue, { VNode, VNodeData } from "vue";

import { isKeyboardKey } from "@/common/lib";

export default Vue.extend({
  props: {
    label: {
      type: String,
      default: "",
    },

    autocomplete: {
      type: String,
      default: "off",
    },

    disableLabel: {
      type: Boolean,
      default: false,
    },

    placeholder: {
      type: String,
      default: "",
    },

    keepPlaceholder: {
      type: Boolean,
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    preventPasswordManager: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "text",
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    value: {
      type: [String, Number],
      required: false,
      default: "",
    },

    cursor: {
      type: String,
      default: "auto",
    },

    textarea: {
      type: Boolean,
      default: false,
    },

    resize: {
      type: String,
      default: "none",
    },

    rows: {
      type: String,
      default: "2",
    },

    cols: {
      type: String,
      default: "20",
    },

    inputClass: {
      type: [Object, String],
      default: "",
    },

    name: {
      type: String,
      default: "",
    },

    classAppend: {
      type: String,
      default: "",
    },

    classPrepend: {
      type: String,
      default: "",
    },

    alignRight: {
      type: Boolean,
      default: false,
    },

    blurOnEsc: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    inputClasses(): Record<string, boolean> {
      let inputClass: Record<string, boolean> | string = this.inputClass;
      if (typeof inputClass === "string") {
        inputClass = { [inputClass.trim()]: true };
      }

      return {
        input: true,
        empty: !this.value,
        "text-end": this.alignRight,
        ...inputClass,
      };
    },
  },

  mounted() {
    if (this.autofocus) {
      this.onFocus();
    }
  },

  methods: {
    onInput(e: any): void {
      if (this.value) {
        e.currentTarget.classList.remove("empty");
      } else {
        e.currentTarget.classList.add("empty");
      }
      this.$emit("input", e.target.value);
    },
    onBlur(e: any): void {
      e.currentTarget.placeholder = this.placeholder;
      this.$emit("blur", e);
    },
    onFocus(e?: any): void {
      const input = this.$refs.input as HTMLElement;
      if (!input) return;

      if (document.activeElement !== input) {
        this.$nextTick(() => input.focus());
      }

      if (e) {
        if (!this.keepPlaceholder) {
          e.currentTarget.placeholder = "";
        }
        this.$emit("focus", e);
      }
    },

    genAttrs() {
      let attrs: Record<string, any> = {
        ...this.$attrs,
        autofocus: this.autofocus,
        autocomplete: this.autocomplete,
        disabled: this.disabled,
        readonly: this.readonly,
        type: this.type,
        placeholder: this.placeholder,
      };

      if (attrs.type === "password" && this.preventPasswordManager) {
        // rather hacky way to disable browser's password manager
        // for 'password' input type we pretend that it's a credit card security code
        // apparently this won't trigger "Save password" dialog
        attrs = { ...attrs, autocomplete: "off cc-csc" };
      }

      if (this.textarea) {
        attrs = { ...attrs, ...this.genTextAreaAttrs() };
      }

      if (this.name) {
        attrs = { ...attrs, ...this.genNameAttrs() };
      }
      return attrs;
    },

    genStyles() {
      let attrs = {
        cursor: this.cursor,
      };
      if (this.textarea) {
        attrs = { ...attrs, ...this.genTextAreaStyles() };
      }
      return attrs;
    },

    genTextAreaAttrs(): any {
      const textAreaAttrs = {
        rows: this.rows,
        cols: this.cols,
      };
      return textAreaAttrs;
    },

    genNameAttrs(): any {
      const nameAttrs = {
        name: this.name,
      };
      return nameAttrs;
    },

    genTextAreaStyles(): any {
      const textAreaStyles = {
        resize: this.resize,
      };
      return textAreaStyles;
    },

    genInputTextareaTag(): string {
      if (this.textarea) {
        return "textarea";
      }
      return "input";
    },

    focus() {
      const input = this.$refs.input as HTMLInputElement;
      input.focus();
    },

    blur() {
      const input = this.$refs.input as HTMLInputElement;
      input.blur();
    },

    select() {
      const input = this.$refs.input as HTMLInputElement;
      input.select();
    },

    genAddon(addon: string, addonClass: string) {
      const addonSlot = this.$scopedSlots[addon];
      if (addonSlot) {
        const addonData: VNodeData = {
          staticClass: addon,
          style: {
            cursor: this.cursor,
          },
          ref: addon,
        };
        const addonContent = this.$createElement(
          "span",
          { staticClass: "input-addon-content", class: [addonClass] },
          addonSlot({}),
        );
        return this.$createElement("div", addonData, [addonContent]);
      }
    },
  },

  render(h): VNode {
    const children: any[] = [];

    const dataLabel: VNodeData = {
      staticClass: "ax-input",
    };

    const dataInput: VNodeData = {
      attrs: this.genAttrs(),
      style: this.genStyles(),
      on: {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
        keyup: (e: KeyboardEvent) => {
          if (this.blurOnEsc && isKeyboardKey(e, "esc")) {
            this.blur();
          }
        },
      },
      domProps: {
        value: this.value,
      },
      class: this.inputClasses,
      ref: "input",
    };

    const prepend = this.genAddon("prepend", this.classPrepend);
    if (prepend) children.push(prepend);
    children.push(h(this.genInputTextareaTag(), dataInput));

    if (!this.disableLabel) {
      const dataSpanLabel: VNodeData = {
        staticClass: "label",
        style: {
          cursor: this.cursor,
        },
        domProps: {
          innerHTML: this.label || this.placeholder,
        },
      };
      children.push(h("span", dataSpanLabel));
    }

    const append = this.genAddon("append", this.classAppend);
    if (append) children.push(append);

    return h("label", dataLabel, children);
  },
});
