import Vue from "vue";
import { sync } from "vuex-router-sync";

import { MessageData } from "@/common/iframe-bridge";
import { AppEvents } from "@/common/inspect";
import router from "@/router";
import store from "@/store";
import installVendors from "@/vendor";

import App from "./App.vue";
import registerServiceWorker from "./service-worker/registerServiceWorker";

(function emitStarting() {
  // Safely emitting event, to indicate that app started its initialization routine.
  // This is required to handle the case when app itself is loaded inside iframe,
  // it might happen when session (cookie) became invalid, e.g. by logout in different browser tab.
  // Eventually, parent app should capture this event and will trigger app reload.
  try {
    const messageData: MessageData = { key: AppEvents.starting, data: {} };
    const message = JSON.stringify(messageData);
    if (window) {
      const { parent } = window;
      parent.postMessage(message, "*");
    }
  } catch (e) {
    console.error(e);
  }
}());

installVendors();
sync(store, router);

Vue.config.productionTip = false;

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");

registerServiceWorker({
  app,
  router,
});
