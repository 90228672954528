import { ActionContext } from "vuex";

import { isSketchProject } from "@/common/axshare";
import { isDevelopment } from "@/common/environment";
import {
  breadcrumbToFolderNode,
  breadcrumbToWorkspaceNode,
  FilesystemNodeFolder,
  FilesystemNodeInvitation,
  FilesystemNodeRoot,
  FilesystemNodeShortcut,
  FilesystemNodeType,
  FilesystemNodeTypes,
  FilesystemNodeWorkspace,
  toInvitationNode,
  toShortcutNode,
  toWorkspaceNode,
} from "@/common/fs";
import { asError } from "@/common/lib";
import { FilesystemFolderInfo } from "@/generated/models";
import {
  archiveWorkspace,
  getFilesystemRootFolderContents,
  getfolderContents,
  getFolderNavByFilesystemId,
  getFolderNavById,
  getFolderNavByShortcutId,
  getUserWorkspaces,
  getNextWorkspaceName,
  renameFolder,
  renameWorkspace,
  workspaceInvitationRespond,
  workspaceCreate,
  moveItems,
  favoriteWorkspace,
  shareOrInviteToFileSystem,
  updateFilesystemOwner,
  createFolder,
} from "@/services/fs.service";
import { BreadcrumbJson } from "@/services/models/filesystem";
import { ChangeProjectInfo } from "@/services/models/project";
import {
  changeProjectInfo, getFirstPage, getProjectInfo, toggleDiscussions,
} from "@/services/project.service";
import { asyncAction } from "@/store/async-action";
import { ProjectFetchExpo } from "@/store/expo/actionTypes";
import {
  ActionPayloadMap,
  ActionTypes,
  Fetch,
  Navigate,
  ProjectLoad,
  WorkspacesRefresh,
  NavigationRestore,
  FirstPageLoad,
} from "@/store/fs/actionTypes";
import { AxShareFilesystem, rootNode } from "@/store/fs/state";
import { AxShare } from "@/store/state";
import { ActionTree } from "@/store/typed";

import * as mutations from "./mutationTypes";

type WorkspaceNode = FilesystemNodeRoot | FilesystemNodeWorkspace | FilesystemNodeInvitation;

const actions: ActionTree<AxShareFilesystem, AxShare, ActionPayloadMap> = {
  async [ActionTypes.WorkspacesLoad]({ commit, getters }) {
    const workspaceNodes = await getWorkspaceNodes();

    // bad pattern, consider removing
    const { workspaces } = getters;
    preserveState(workspaceNodes, workspaces);

    workspaceNodes.forEach(ws => commit(new mutations.NodeRemember(ws)));
  },

  async [ActionTypes.WorkspacesRefresh]({ commit, getters }) {
    const workspaceNodes = await getWorkspaceNodes();

    // bad pattern, consider removing
    const { workspaces } = getters;
    preserveState(workspaceNodes, workspaces);

    Object.keys(workspaces).forEach(id => commit(new mutations.NodeRemove(id)));
    workspaceNodes.forEach(ws => commit(new mutations.NodeRemember(ws)));
  },

  async [ActionTypes.ProjectLoad](context, { shortcut }) {
    const projectLoadActions = [];
    let node: FilesystemNodeShortcut | undefined = context.getters.getFsNode(shortcut);
    // missing node's parent means that it's not yet attached to the fs tree,
    // possibly node was loaded separately before
    // so we restore tree now
    if (!node || !node.parent) {
      projectLoadActions.push(context.dispatch(new NavigationRestore(shortcut, "shortcut")));
    }

    projectLoadActions.push(
      asyncAction(
        context,
        ActionTypes.ProjectLoad,
        () => getProjectInfo(shortcut),
        project => new mutations.NodeRemember(
          toShortcutNode(project, context.getters.tryFindParent(project.Shortcut.toLowerCase())),
        ),
      ),
    );

    await Promise.all(projectLoadActions);

    node = context.getters.getFsNode(shortcut);
    if (node && isSketchProject(node)) {
      await context.dispatch(new ProjectFetchExpo(shortcut));
    }

    context.dispatch(new FirstPageLoad(shortcut));
  },

  async [ActionTypes.ProjectUpdate](context, { shortcut, projectInfo }) {
    await asyncAction(context, ActionTypes.ProjectUpdate, () => changeProjectInfo(shortcut, projectInfo));
    await context.dispatch(new ProjectLoad(shortcut));
  },

  async [ActionTypes.ProjectRename](context, { shortcut, name }) {
    await asyncAction(context, ActionTypes.ProjectRename, () => changeProjectInfo(shortcut, { Name: name }));
    context.commit(new mutations.ProjectRename(shortcut, name));
  },

  async [ActionTypes.ProjectToggleFeedback](context, { shortcut, enabled }) {
    await asyncAction(context, ActionTypes.ProjectToggleFeedback, () => toggleDiscussions(shortcut, enabled));
    context.commit(new mutations.ProjectToggleFeedback(shortcut, enabled));
  },

  async [ActionTypes.ProjectToggleUserOnly](context, { shortcut, isUserOnly }) {
    const model: Partial<ChangeProjectInfo> = {
      IsUserOnly: isUserOnly,
    };

    await asyncAction(context, ActionTypes.ProjectUpdate, () => changeProjectInfo(shortcut, model));
    context.commit(new mutations.ProjectToggleUserOnly(shortcut, isUserOnly));
  },

  async [ActionTypes.ProjectShare]({ getters }, { node, usersInvite, recaptchaToken }): Promise<FilesystemFolderInfo | undefined> {
    const closestFolderId: string | undefined = getters.getClosestFolderId(node.id);
    if (!closestFolderId) throw new Error("Couldn't determine project's containing folder");
    const { workspaceName } = (await getNextWorkspaceName()).Vars;

    const newWorkspace = await workspaceCreate(workspaceName);
    if (newWorkspace) {
      const { RootFolderId, FilesystemInfo } = newWorkspace;
      try {
        await moveItems([node], closestFolderId, RootFolderId);
      } catch (error) {
        const message = asError(error).message;
        throw new Error(`Workspace has been created, but move project and invited users has failed: ${message}`);
      }

      try {
        const shouldInvite = !!usersInvite.userEmails && Array.isArray(usersInvite.userEmails)
          ? usersInvite.userEmails.length > 0
          : !!usersInvite.userEmails;
        if (shouldInvite) {
          const model = {
            ...usersInvite,
            filesystemId: FilesystemInfo.FilesystemId,
          };

          await shareOrInviteToFileSystem(model, recaptchaToken);
        }
      } catch (error) {
        const message = error instanceof Error ? error.message : error as any;
        throw new Error(`Project has been moved to a new workspace, but inviting users has failed: ${message}`);
      }

      return newWorkspace;
    }
  },

  async [ActionTypes.Navigate](store, { node }) {
    const { commit } = store;

    commit(new mutations.NodeRemember(node));
    commit(new mutations.Navigating(node.id, { inProgress: true }));
    try {
      await store.dispatch(new Fetch(node));
      commit(new mutations.Navigating(node.id, { inProgress: false }));
    } catch (error) {
      if (isDevelopment) {
        console.error(`Error occurred during filesystem navigation, ${error}`);
      }
      commit(new mutations.Navigating(node.id, { inProgress: false, isError: true, error }));
    }
  },

  async [ActionTypes.FetchCurrent]({ getters, dispatch }) {
    const node = getters.current.node;
    if (node) {
      await dispatch(new Fetch(node));
    }
  },

  async [ActionTypes.Fetch](store, { node }: Fetch) {
    if (node.id === rootNode.id) return;

    const { commit } = store;
    commit(new mutations.NodeRemember(node));
    commit(new mutations.Fetching(node.id, true));
    if (node.type === FilesystemNodeType.Workspace) {
      await workspaceNavigate(node.id, store);
    } else {
      const contents = await getContents(node.id, node.type);
      if (contents) {
        if (node.type === FilesystemNodeType.Folder) {
          const viewOnly = contents.AdditionalVars.isFsViewer === "true";
          commit(new mutations.SetViewOnly(node, viewOnly));
        }
        commit(new mutations.SetContents(node, contents));
      }
    }
    commit(new mutations.Fetching(node.id, false));
  },

  async [ActionTypes.NavigationRestore](store, { id, idType, silent }) {
    const { dispatch, commit } = store;

    if (!silent) {
      commit(new mutations.Navigating(id, { inProgress: true }));
    }

    try {
      const navigationFn =
        // eslint-disable-next-line no-nested-ternary
        idType === "shortcut"
          ? getFolderNavByShortcutId(id)
          : idType === "folder"
            ? getFolderNavById(id)
            : getFolderNavByFilesystemId(id);
      const navigation = await navigationFn;
      const nodes = [
        rootNode,
        ...navigation.breadcrumbJson.map<FilesystemNodeTypes>(breadcrumb => breadcrumbToFilesystemNode(breadcrumb, store.state)),
      ];

      // set parent and remember in store
      for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];
        commit(new mutations.NodeRemember(node));
        commit(new mutations.SetParentNode(node.id, nodes[i - 1]));
      }

      if (!silent) {
        commit(new mutations.Navigating(id, { inProgress: false }));
      }

      if (idType === "shortcut") {
        // and navigate to last
        const lastNode = nodes[nodes.length - 1];
        await dispatch(new Navigate(lastNode));
      }
    } catch (error) {
      if (isDevelopment) {
        console.error(`Error occurred during filesystem navigation restore, ${error}`);
      }
      if (!silent) {
        commit(
          new mutations.Navigating(id, {
            inProgress: false,
            isError: true,
            error,
          }),
        );
      }
    }
  },

  async [ActionTypes.FolderCreate](context, { folderCreateModel }) {
    const folderRowJs = await createFolder(folderCreateModel);
    if (folderRowJs) {
      const { node } = context.getters.current;
      context.commit(new mutations.FolderAdd(node, folderRowJs));
    }
  },

  async [ActionTypes.FolderRename](context, { folderId, name }) {
    const folder: FilesystemNodeFolder = context.getters.getFsNode(folderId);
    const { parent } = folder;
    let currentFolderId = "";
    if (parent) {
      if (parent.type === FilesystemNodeType.Folder) {
        currentFolderId = parent.id;
      } else if (parent.type === FilesystemNodeType.Workspace) {
        currentFolderId = parent.rootFolderId;
      }
      await asyncAction(
        context,
        ActionTypes.FolderRename,
        () => renameFolder({
          FolderId: folderId,
          Name: name,
          currentFolderId,
        }),
        () => new mutations.FolderRename(folderId, name),
      );
    }
  },

  async [ActionTypes.WorkspaceRename](context, { model }) {
    return asyncAction(
      context,
      ActionTypes.WorkspaceRename,
      () => renameWorkspace(model),
      () => new mutations.WorkspaceRename(model.FilesystemId, model.Name),
    );
  },

  async [ActionTypes.WorkspaceArchive](context, { id, archive }) {
    return asyncAction(
      context,
      ActionTypes.WorkspaceArchive,
      () => archiveWorkspace(id, archive),
      () => new mutations.WorkspaceArchive(id, archive),
    );
  },

  async [ActionTypes.FavoriteWorkspace](context, { id, favorite }) {
    return asyncAction(
      context,
      ActionTypes.FavoriteWorkspace,
      () => favoriteWorkspace(id, favorite),
      () => new mutations.FavoriteWorkspace(id, favorite),
    );
  },

  [ActionTypes.InvitationRespond](context, { id, accept }) {
    return asyncAction(
      context,
      ActionTypes.InvitationRespond,
      () => workspaceInvitationRespond(id, accept),
      () => new mutations.NodeRemove(id),
    );
  },

  async [ActionTypes.NodeRemove]({ commit, getters }, { nodes, parentFolder }) {
    const parentNode: FilesystemNodeTypes = getters.getNodeByFolderId(parentFolder);
    nodes.forEach(node => {
      commit(new mutations.NodeRemoveContents(parentNode.id, node.id));
      commit(new mutations.NodeRemove(node.id));
    });
  },

  async [ActionTypes.UpdateFilesystemOwner](context, { filesystemId, email }) {
    await asyncAction(context, ActionTypes.UpdateFilesystemOwner, () => updateFilesystemOwner(filesystemId, email));
    await context.dispatch(new WorkspacesRefresh());
  },

  async [ActionTypes.FirstPageLoad]({ commit }, { shortcut }) {
    const firstPage = await getFirstPage(shortcut);
    commit(new mutations.SetFirstPage(shortcut, firstPage));
  },
};

async function getWorkspaceNodes(): Promise<WorkspaceNode[]> {
  const listingJs = await getUserWorkspaces();
  const workspaceNodes = [
    rootNode,
    ...listingJs.Filesystems.map(fs => toWorkspaceNode(fs, rootNode)),
    ...listingJs.Invitations.map(fs => toInvitationNode(fs, rootNode)),
  ];
  return workspaceNodes;
}

async function workspaceNavigate(id: string, store: ActionContext<AxShareFilesystem, AxShare>) {
  const { commit } = store;
  const { Listing, FilesystemInfo } = await getFilesystemRootFolderContents(id);
  const node = toWorkspaceNode(FilesystemInfo, rootNode);
  // explicitly set rootFolderId for Workspace type
  const rootFolderId = Listing.AdditionalVars.fsRootFolderId;
  if (rootFolderId) {
    node.rootFolderId = rootFolderId;
  }
  commit(new mutations.SetContents(node, Listing));
  commit(new mutations.SetListingNode(id, FilesystemInfo));
}

function preserveState(workspaceNodes: WorkspaceNode[], workspacesInStore: Record<string, FilesystemNodeWorkspace>) {
  for (const workspace of workspaceNodes) {
    if (workspace.type === FilesystemNodeType.Workspace) {
      trySet(
        workspace,
        workspacesInStore,
        w => w.rootFolderId,
        (ws, rootFolderId) => {
          // eslint-disable-next-line no-param-reassign
          ws.rootFolderId = rootFolderId;
        },
      );
      trySet(
        workspace,
        workspacesInStore,
        w => w.owner,
        (ws, owner) => {
          // eslint-disable-next-line no-param-reassign
          ws.owner = owner;
        },
      );
    }
  }
}

function trySet<T extends { id: string }, V>(
  item: T,
  itemsInStore: Record<string, T>,
  getter: (t: T) => V | undefined,
  setter: (t: T, v: V) => void,
) {
  if (getter(item)) return;
  const inStore = itemsInStore[item.id];
  if (!inStore) return;
  const val = getter(inStore);
  if (val) {
    setter(item, val);
  }
}

async function getContents(id: string, type: FilesystemNodeType) {
  switch (type) {
    case FilesystemNodeType.Folder:
      return getfolderContents(id);
    case FilesystemNodeType.Root:
      return getUserWorkspaces();
    default:
      throw new Error(`Unexpected type ${type} for fetching filesystem contents.`);
  }
}

function breadcrumbToFilesystemNode(
  breadcrumb: BreadcrumbJson,
  state: AxShareFilesystem,
): FilesystemNodeWorkspace | FilesystemNodeFolder {
  const existing = state.nodes[breadcrumb.id];
  if (breadcrumb.isFilesystem) {
    return breadcrumbToWorkspaceNode(breadcrumb, existing);
  }
  return breadcrumbToFolderNode(breadcrumb, existing);
}

export default actions;
