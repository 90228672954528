
import Vue from "vue";

import { SsoResponse } from "@shared/models";

import { getUserName } from "@/common/axshare";
import { sortDirectoryTree } from "@/common/fs";
import { orderBy } from "@/common/lib";
import { getUserDirectoryTree } from "@/services/fs.service";
import {
  FilesystemEntry, FilesystemEntryTreeNode, FilesystemEntryTree, DirectoryTreeNode,
} from "@/services/models";
import { EntryType } from "@/services/models/notification";
import { getUserFsEntryTree } from "@/services/notification.service";
import { AxShare } from "@/store/state";

import AxIcon from "@/components/AxIcon.vue";
import AxInput from "@/components/AxInput.vue";
import AxProjectThumbnail from "@/components/AxProjectThumbnail.vue";
import AxTree from "@/components/AxTree.vue";
import AxWorkspaceIcon from "@/components/AxWorkspaceIcon.vue";
import AxFolder from "@/components/icons/AxFolder.vue";
import { TreeNode } from "@/components/types/AxTree";

const allProjects = {
  id: "00000000-0000-0000-0000-000000000000",
  name: "All Projects",
};

export default Vue.extend({
  components: {
    AxTree,
    AxProjectThumbnail,
    AxWorkspaceIcon,
    AxInput,
    AxIcon,
    AxFolder,
  },

  props: {
    value: {
      type: Object,
      default: null,
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    select: {
      type: String,
      default: null,
    },

    showAll: {
      type: Boolean,
      default: false,
    },

    renderDefaultAllItem: {
      type: Boolean,
      default: true,
    },

    workspacesOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      active: [this.select],
      fsEntries: undefined as FilesystemEntryTree | undefined,
      workspacesRoot: undefined as DirectoryTreeNode | undefined,
      inputFilter: "",
      selectedName: "",
      isExpanded: false,
    };
  },

  computed: {
    user(): SsoResponse | undefined {
      const { user } = this.$store.state as AxShare;
      return user.userInfo;
    },

    tree(): TreeNode[] {
      let tree = [] as TreeNode[];

      if (this.workspacesOnly) {
        if (!this.workspacesRoot) return [];
        tree = this.generateWorkspaceNode(this.workspacesRoot).children;
      } else {
        if (!this.fsEntries) return [];
        tree = this.fsEntries
          .filter(n => this.userIsOwner(n) || this.showAll || n.explicitlyJoined)
          .map(this.generate);
      }

      if (this.inputFilter && this.inputFilter !== this.selectedName) {
        this.filterItems(tree);
      }

      if (this.renderDefaultAllItem) {
        tree.unshift({
          id: allProjects.id,
          name: allProjects.name,
          data: {
            id: allProjects.id,
            name: allProjects.name,
            targetType: EntryType.All,
          },
          children: [],
        });
      }
      return tree;
    },
  },

  watch: {
    inputFilter() {
      if (this.inputFilter !== this.selectedName) this.isExpanded = true;
    },
  },

  async created() {
    this.loading = true;
    try {
      if (this.workspacesOnly) {
        this.workspacesRoot = (await getUserDirectoryTree()).workspacesRoot;
      } else {
        this.fsEntries = await getUserFsEntryTree();
      }
    } catch {
      //
    }
    this.loading = false;
  },

  methods: {
    generate(node: FilesystemEntryTreeNode): TreeNode {
      const hasChildren = node.entries && node.entries.length > 0;
      const children: Array<TreeNode<FilesystemEntry>> = hasChildren
        ? orderBy(node.entries, n => n.Name).map(n => ({
          id: n.shortcutId,
          name: n.Name,
          data: { ...n, targetType: EntryType.Shortcut },
          children: [],
        }))
        : [];

      return {
        id: node.folderId,
        name: node.folderName,
        data: { ...node, targetType: EntryType.Filesystem, owner: node.owner },
        children,
      };
    },

    generateWorkspaceNode(node: DirectoryTreeNode): TreeNode {
      const hasChildren = node.children && node.children.length > 0;
      const showAll = this.showAll;
      const children = hasChildren
        ? sortDirectoryTree(node.children)
          .filter(n => showAll || this.userIsOwner(n) || n.explicitlyJoined)
          .map(this.generateWorkspaceNode)
        : [];

      const targetType = node.isFolder ? EntryType.Folder : EntryType.Filesystem;

      return {
        id: node.id,
        name: node.name,
        data: { ...node, targetType },
        children,
      };
    },

    getOwnerDescription(node: FilesystemEntryTreeNode) {
      if (!this.userIsOwner(node)) {
        return `(Owner: ${this.getUserName(node.owner)})`;
      }
      return "";
    },

    userIsOwner(node: FilesystemEntryTreeNode | DirectoryTreeNode) {
      if (this.user) {
        return this.user.userId === node.owner.userId;
      }
      return false;
    },

    getUserName(user: SsoResponse): string {
      return getUserName(user) || user.userEmail || "";
    },

    selected(items: any) {
      const selected = this.multiple ? items : items[0];
      this.$emit("input", selected);

      if (selected) {
        const itemName = this.workspacesOnly
          ? items[0].name
          : items[0].Name ?? items[0].folderName;

        if (this.selectedName !== itemName) {
          this.isExpanded = false;
          this.selectedName = itemName;
          this.inputFilter = itemName;
        }
      }
    },

    isWorkspace(node: any) {
      const { targetType } = node;
      return targetType === EntryType.Filesystem;
    },

    isFolder(node: any) {
      const { targetType } = node;
      return targetType === EntryType.Folder;
    },

    isProject(node: any) {
      const { targetType } = node;
      return targetType === EntryType.Shortcut;
    },

    filterItems(treeItems: TreeNode[]) {
      const itemsToDelete = [];
      for (const item of treeItems) {
        this.filterItems(item.children);
        const visible = item.name.toLowerCase().indexOf(this.inputFilter.toLowerCase()) >= 0;
        if (!visible && item.children.length === 0) {
          itemsToDelete.push(item);
        }
      }

      for (const delItem of itemsToDelete) {
        const delIndex = treeItems.indexOf(delItem);
        if (delIndex >= 0) {
          treeItems.splice(delIndex, 1);
        }
      }
    },
  },
});
